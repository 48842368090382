import React , {useEffect,useState} from "react";
import { popularProducts } from "../../fakeData/Products";
import axios from "axios"


import Card from "../card/Card";

function PopularProduct() {

  const  [topSellingProducts,setTopSellingProducts] = useState([])
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  


  useEffect(() => {
    const getTopSellingProducts= async()=>{
      try{
        const apiResponse =  await axios.get(`${baseUrl}/api/v1/products/topSellingProducts`)
         if(apiResponse && apiResponse.data.length>0){
          const activeProducts = apiResponse.data.filter((each)=>{
            if(each.productId.status === "ACTIVE"){
              return each
            }
           })
          setTopSellingProducts(activeProducts)

         }
      
      }
      catch(e){
        // alert("error occured while fetching topselling data")
        console.log(e)
      }
    }
    getTopSellingProducts()
  
  }, []);
  return (
    <div
      id="discount"
      className="bg-gray-50 lg:py-16 py-10 mx-auto max-w-screen-2xl px-3 sm:px-10"
    >
      <div className="mb-10 flex justify-center">
        <div className="text-center w-full lg:w-2/5">
          <h2 className="text-xl lg:text-2xl mb-2 text-black font-semibold">
            Special Offers
          </h2>
          <p className="text-base text-gray-600 leading-6">
            See all our popular products in this week. You can choose your daily
            needs products from this list and get some special offer with free
            shipping.
          </p>
        </div>
      </div>
      <div className="flex">
        <div className="w-full">
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-2 md:gap-3 lg:gap-3 ">
            { topSellingProducts && topSellingProducts.length > 0 ? topSellingProducts.map((data, index) => (
              <Card key={index} data={data} />
            )):null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopularProduct;
