let baseUrl = "";
switch (process.env.REACT_APP_STATUS) {
  case "DEV":
    baseUrl = process.env.REACT_APP_DEV_URL;
    break;
  case "STAGE":
    baseUrl = process.env.REACT_APP_STAGE_URL;
    break;
  case "PROD":
    baseUrl = process.env.REACT_APP_PROD_URL;
    break;
}

export const FooterData = [
  {
    titleHeader: "Company",
    title: [
      {
        Name: "About Us",
        href: "about-us",
        target:"",
      },
      {
        Name: "Contact Us",
        href: "contact-us",
        target:"",
      },
      {
        Name: "Terms & conditions",
        href: `${baseUrl}/terms-and-conditions/`,
        target:"_blank",
      },
      {
        Name: "Privacy Policy",
        href: `${baseUrl}/privacy-policy/`,
        target:"_blank",
      },
    ],
  },

  {
    titleHeader: "My Account",
    title: [
      {
        Name: "Update Profile",
        href: "user/update-profile",
      },
      {
        Name: "My Orders",
        href: "user/my-orders",
      },
      {
        Name: "Saved Addresses",
        href: "user/address",
      },
      {
        Name: "Change Password",
        href: "user/change-password",
      },
    
    ],
  },

];
