import React, { useEffect, useState,useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SliceCategory from "../sliceCategory/SliceCategory";
import NotFoundProduct from "../notFoundProduct/NotFoundProduct";
import { useSelector, useDispatch } from "react-redux";
import { getProductByParentCategory } from "../../fakeData/Products";
import {
  addToCart,
  decreaseCart,
  incrementCart,
} from "../../store/reducers/cartSlice";
import Card from "../card/Card";
import { ShimmerPostDetails } from "react-shimmer-effects";



const Search = () => {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [subCategories, setSubCategories] = useState([]);
  const [relatedCategoryProducts, setRelatedCategoryProducts] = useState([]);
  const [searchedproducts,setSearchedProducts]= useState([])

  const [mainData,setMainData]= useState([])

  const [subCategoryId, setSubCategoryId] = useState("");
  const [showShimmer,setShowShimmer]= useState(true)

  const url = window.location.href;
  const urlObject = new URL(url);
  const categoryId = urlObject.searchParams.get("Category");
  const searchedName = urlObject.searchParams.get("name");

  useEffect(() => {
    const url = window.location.href;
    const urlObject = new URL(url);
    const categoryId = urlObject.searchParams.get("Category");
    const searchedName = urlObject.searchParams.get("name");

    const general = async () => {
      window.scrollTo(0, 0);
      try {
        if(categoryId !== null){
          
          const allSubCategoriesResponse = await axios.get(
            `${baseUrl}/api/v1/products/fetchAllSubCategories`
          );
          let activeSubCatageories = []
          if(allSubCategoriesResponse && allSubCategoriesResponse.data.length>0){
            activeSubCatageories = allSubCategoriesResponse.data.filter((each)=>{
              if(each.status==="ACTIVE" && each.category === categoryId){
                return each
              }
            })
          }
         
          setSubCategories(activeSubCatageories);



          const allProductsResponse = await axios.get(
            `${baseUrl}/api/v1/products/fetchAllProducts`
          );
          let activeProducts =[]
          if(allProductsResponse.data.length>0){
            activeProducts= allProductsResponse.data.filter((each)=>{
              if(each.status === "ACTIVE"){
                return each
              }
            })
          }

          if(activeProducts.length>0){

          }
          const filteredRelatedCategoryProducts = activeProducts.filter(
            (each) => each.category === categoryId
          );
          setRelatedCategoryProducts(filteredRelatedCategoryProducts);
          setMainData(filteredRelatedCategoryProducts)
          setShowShimmer(false)

        }
        else if(searchedName !== null){
         
          const allProductsResponse = await axios.get(
            `${baseUrl}/api/v1/products/fetchAllProducts`
          );
          let activeProducts = []
          if(allProductsResponse.data.length>0){
             activeProducts = allProductsResponse.data.filter((each)=>{
              if(each.status === "ACTIVE"){
                return each
              }
             })
          }
          const queryName  =  searchedName.toLowerCase()
          const filteredSearchProducts = activeProducts.filter(
            (each) => each.name.toLowerCase().includes(queryName) 
          );
         
          setSearchedProducts(filteredSearchProducts);
          setMainData(filteredSearchProducts)
          setShowShimmer(false)
        }

      } catch (e) {
        // alert("error occured while fetching data");
        console.log(e)
      }
    };
    general();
  }, [location.search]);

  const setSelectedCategoryId = (id) => {
    setSubCategoryId(id);
  };



  useEffect(() => {
    const logic = () => {
      let filteredProducts = [];
      if (subCategoryId !== "" && relatedCategoryProducts.length > 0) {
        filteredProducts = relatedCategoryProducts.filter(
          (each) => each.subCategory === subCategoryId
        );
      }
       setMainData(filteredProducts)
      return filteredProducts;
    };
    logic()
  }, [subCategoryId])

 if(showShimmer){
  return(
    <>
    <ShimmerPostDetails card cta variant="SIMPLE" />
 <ShimmerPostDetails card cta variant="EDITOR" />
</> 

  )
 }
 else{
  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="flex pt-10 lg:py-12">
          <div className="flex w-full">
            <div className="w-full">
              {(subCategories.length > 0 && searchedName === null) ?(
                <div>
                  <h4 className="ml-5">Sub Categories</h4>
                   <SliceCategory
                  data={subCategories}
                  setSelectedCategoryId={setSelectedCategoryId}
                />
                </div>
               
              ):""}
              <div className="flex">
                <div className="w-full">
                <h4 className="mb-6">Products</h4>
                <div className= {mainData.length > 0 ? "grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 xl:grid-cols-5 2xl:grid-cols-6 gap-2 md:gap-3 lg:gap-3":""}>
                  
                    {
                      mainData.length > 0 ? mainData.map((data,index) => (<Card key={index} data={{productId:data}} />)) : <NotFoundProduct />
                    }
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  
 }
  

  
};

export default Search;
