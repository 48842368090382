import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FiTrash2, FiMapPin, FiCheckCircle, FiUser, FiPhone, FiMap } from 'react-icons/fi';
import {BiSolidCity} from 'react-icons/bi'
import {MdOutlineEmail} from 'react-icons/md'
import NoAddress from "../noAddress/noAddressFound";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { ShimmerPostDetails } from "react-shimmer-effects";

import axios from "axios";

function Address() {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  let navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    const safeRoute =()=>{
      const loginToken = localStorage.getItem("loginToken")
      const userId = localStorage.getItem("userId")
      const secondStage = localStorage.getItem("secondStage")
      if(loginToken=== null || userId === null || secondStage === null){
        navigate("/")
      }
    }
    safeRoute()
  }, []);
 

  const [sameAddress, setSameAddress] = useState(true);
  const [savedAddress,setSavedAddress]= useState([])
  const [deletePopup,setDeletePopup] = useState(false)
  const [deleteAddressId,setDeleteAddressId]= useState("")
  const [showShimmer,setShowShimmer]= useState(true)

  const [shippingAddress, setShippingAddress] = useState({
    Name: "",
    email: "",
    phoneNumber: "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipPostal: "",
  });
  const [billAddress, setBillingAddress] = useState({
    billingName: "",
    billingEmail: "",
    billingPhoneNumber: "",
    billingAddress: "",
    billingCity: "",
    billingState: "",
    billingCountry: "",
    billingZipPostal: "",
  });
  const [reload,setReload]= useState(false)

  useEffect(()=>{
    const headers = {
        authorization: localStorage.getItem("loginToken"),
      };
    
    const getUserAddresses= async()=>{
        try{
            const userAddressResponse = await axios.get(`${baseUrl}/api/v1/profile/getSavedAddresses`,{
                headers,
               })
              
               setSavedAddress(userAddressResponse.data)
               setShowShimmer(false)
        }
        catch(e){
            console.log(e)
        }
      
      
    }
    getUserAddresses()
  },[reload])

  const handleCheckboxChange = () => {
    setSameAddress(!sameAddress);
  };



const deleteSavedAddess= async(id)=>{
    const headers = {
        authorization: localStorage.getItem("loginToken"),
      };
    
    try{
        const userdeleteAddressResponse = await axios.delete(`${baseUrl}/api/v1/profile/deleteSavedAddresses`,{
            headers,
            data:{
                "savedAddressId": id,
            }
           })
           setReload(!reload)
    }
    catch(e){
        console.log(e)
    }
   
}

const updateAddress= async(id)=>{
    const headers = {
        authorization: localStorage.getItem("loginToken"),
      };
    
    try{
        const {
            Name,
            address,
            city,
            zipPostal,
            state,
            email,
            country,
            phoneNumber,
          } = shippingAddress;
          const {
            billingName,
            billingAddress,
            billingCity,
            billingZipPostal,
            billingState,
            billingEmail,
            billingCountry,
            billingPhoneNumber,
          } = billAddress;
        const updateAddressResponse = await axios.put(`${baseUrl}/api/v1/profile/updateSavedAddresses`,
            {
                savedAddressId: id,
                shippingAddress: {
                    name: Name,
                    email: email,
                    phone: phoneNumber,
                    address: address,
                    city: city,
                    pincode: zipPostal,
                    state: state,
                    country: country,
                  },
                billingAddress: {
                    name: billingName,
                    email: billingEmail,
                    phone: billingPhoneNumber,
                    address: billingAddress,
                    city: billingCity,
                    pincode: billingZipPostal,
                    state: billingState,
                    country: billingCountry,
                  },
            }
           )
    }
    catch(e){
        console.log(e)
    }
   
}

  

  const handleAddAddress = async () => {
    const headers = {
      authorization: localStorage.getItem("loginToken"),
    };

    try {
      if (sameAddress) {
        const {
          Name,
          address,
          city,
          zipPostal,
          state,
          email,
          country,
          phoneNumber,
        } = shippingAddress;
        const newAddAddressApiResponse = await axios.post(
          `${baseUrl}/api/v1/profile/addSavedAddresses`,
          {
            shippingAddress: {
              name: Name,
              email: email,
              phone: phoneNumber,
              address: address,
              city: city,
              pincode: zipPostal,
              state: state,
              country: country,
            },
            billingAddress: {
              name: Name,
              email: email,
              phone: phoneNumber,
              address: Address,
              city: city,
              pincode: zipPostal,
              state: state,
              country: country,
            },
          },
          {
            headers,
          }
        );
      } else if (!sameAddress) {
        const {
            Name,
            Address,
            city,
            zipPostal,
            state,
            email,
            country,
            phoneNumber,
          } = shippingAddress;
          const {
            billingName,
            billingAddress,
            billingCity,
            billingZipPostal,
            billingState,
            billingEmail,
            billingCountry,
            billingPhoneNumber,
          } = billAddress;
        const newAddAddressApiResponse = await axios.post(
          `${baseUrl}/api/v1/profile/addSavedAddresses`,
          {
            shippingAddress: {
              name: Name,
              email: email,
              phone: phoneNumber,
              address: Address,
              city: city,
              pincode: zipPostal,
              state: state,
              country: country,
            },
            billingAddress: {
              name: billingName,
              email: billingEmail,
              phone: billingPhoneNumber,
              address: billingAddress,
              city: billingCity,
              pincode: billingZipPostal,
              state: billingState,
              country: billingCountry,
            },
          },
          {
            headers,
          }
        );
      }
    } catch (e) {
        console.log(e)
    }
  };

  if(showShimmer){
    return(
      <ShimmerPostDetails card cta variant="SIMPLE" />
    )
  }
    else
    {
      return (
        <>
        <h6 className="mb-5 text-xl text-black">Saved Address</h6>
        <div className="md:flex flex-wrap">
    
        {
          savedAddress.length > 0 ? savedAddress.map((eachAddress,index)=>{
            return(
              <div className="rounded-2xl m-3   bg-gray-100 p-4 w-full">
              <div className="flex justify-between items-center">
                <h2 className="text-lg font-semibold">Address {index+1}</h2>
                <button 
                 onClick={()=>
                  {
                    // deleteSavedAddess(eachAddress._id)
                    setDeleteAddressId(eachAddress._id)
                    setDeletePopup(true) 
                  }
                  }
                  className="text-red-500 hover:text-red-700"
                >
                  <FiTrash2  size={23}/>
                </button>
              </div>
              <div className="mt-3 md:grid md:grid-cols-2 md:gap-4">
              <div className="flex items-start space-x-4 ">
                <div className="overflow-auto">
                    <h3 className="text-base font-semibold mb-1">Shipping Address</h3>
                    <div>
                   
                    <div className="flex items-center space-x-2 mt-2 overflow-auto  ">
                      <span className="text-emerald-500">
                        <FiUser />
                      </span>
                      <p className="overflow-auto">{eachAddress.shippingAddress.name}</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-2  overflow-auto  max-h-32">
                      <span className="text-emerald-500">
                        <MdOutlineEmail />
                      </span>
                      <p className="overflow-auto">{eachAddress.shippingAddress.email}</p>
                    </div>
                  
                    <div className="flex items-center space-x-2 mt-2  overflow-auto  max-h-32">
                      <span className="text-emerald-500">
                        <FiPhone />
                      </span>
                      <p className="overflow-auto">{eachAddress.shippingAddress.phone}</p>
                    </div>
                    
                    
                    <div className="flex items-center space-x-2 mt-2  overflow-auto  max-h-32">
                      <span className="text-emerald-500">
                        <BiSolidCity/>
                      </span>
                      <p className="overflow-auto">
                        {eachAddress.shippingAddress.city}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 mt-2  overflow-auto  max-h-32">
                      <span className="text-emerald-500">
                        <FiMap />
                      </span>
                      <p  className=" overflow-auto w-[80%]">
                        {eachAddress.shippingAddress.address} | {eachAddress.shippingAddress.state} |{eachAddress.shippingAddress.pincode} | {eachAddress.shippingAddress.country} 
                      </p>
                    </div>
                  </div>
                   
                  </div>
                  
                </div>
              
                <div className="flex items-start space-x-4 mt-5 md:mt-0 ">
                  <div className="overflow-auto">
                    <h3 className="text-base font-semibold mb-1">Billing Address</h3>
                    <div>
                   
                    <div className="flex items-center space-x-2 mt-2 overflow-auto  ">
                      <span className="text-emerald-500">
                        <FiUser />
                      </span>
                      <p className="overflow-auto">{eachAddress.billingAddress.name}</p>
                    </div>
                    <div className="flex items-center space-x-2 mt-2">
                      <span className="text-emerald-500">
                        <MdOutlineEmail />
                      </span>
                      <p className="overflow-auto" >{eachAddress.billingAddress.email}</p>
                    </div>
                  
                    <div className="flex items-center space-x-2 mt-2">
                      <span className="text-emerald-500">
                        <FiPhone />
                      </span>
                      <p className="overflow-auto">{eachAddress.billingAddress.phone}</p>
                    </div>
                    
                    
                    <div className="flex items-center space-x-2 mt-2">
                      <span className="text-emerald-500">
                        <BiSolidCity/>
                      </span>
                      <p className="overflow-auto">
                        {eachAddress.billingAddress.city}
                      </p>
                    </div>
                    <div className="flex items-center space-x-2 mt-2">
                      <span className="text-emerald-500">
                        <FiMap />
                      </span>
                      <p className="overflow-auto">
                        {eachAddress.billingAddress.address} | {eachAddress.billingAddress.state} |{eachAddress.billingAddress.pincode} | {eachAddress.billingAddress.country} 
                      </p>
                    </div>
                  </div>
                   
                  </div>
                </div>
               
              </div>
            </div>
    
            )
          }): <NoAddress />
        }
         </div>
         <Popup
            open={deletePopup}
            onClose={() => setDeletePopup(false)}
            modal
            contentStyle={{
              maxWidth: '400px',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            {close => (
              <div>
                <p className="text-center font-bold ">Are You Sure You Want To Delete This Address? </p>
                <div className="mt-4 flex justify-between">
                  <button
                    className="flex-grow bg-emerald-500 text-white py-2 rounded-md mr-2 hover:bg-emerald-600"
                    onClick={() => {
                      deleteSavedAddess(deleteAddressId)
                      close();
                    }}
                  >
                    Yes
                  </button>
                  <button
                    className="flex-grow bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400"
                    onClick={() => close()}
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </Popup>
       
        </>
    
        
      );

    }

 
}

export default Address;
