import React, { Fragment,useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Formik, Field, Form } from "formik";
import { useDispatch,useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import * as Yup from "yup";
import { isLoginAction } from "../../store/reducers/isOpenSlice";
const Login = ({ setIsOpenRegister,setShowOneLastStep }) => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const handleForgotPassword = () => {
    setShowPrompt(true);
  };

  const [showPassword, setShowPassword] = useState(false);

  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

  const handlePromptSubmit = async () => {

     if(!email.length>0){
      // alert("please enter mail to reset password")
      toast.warn("Please Enter Mail  To Reset Password", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        })
      setEmail("")
      // setShowPrompt(false)
    }
    else{
      try{
        const forgetPassword = await axios.post(`${baseUrl}/api/v1/userAuth/userForgotPassword`,
          {
            "email": email
        }
      )
      if(forgetPassword){
     
        toast.success('Password Reset Mail Sent Successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
          });
        setShowPrompt(false);
        setEmail("")
      }
      }
      catch(e){
         console.log(e)
         toast.error("Please Provide Correct Mail Id", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          
          })
      }

    }
  
  };
  
  const {isOpen }= useSelector(state=>state.isOpen)
  const LoginSchema = Yup.object().shape({
    password: Yup.string().required("Password is required!"),
    email: Yup.string().email("Invalid email").required("Email is required!"),
  });
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required!"),
  });

  const loginUser= async (data)=>{
    const {password,email} = data
   if(password.length<8){
    toast.warn('Password Must Be Atleast 8 Characters', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
   }
   else{
    try{
      const responseFromApi = await  axios.post(`${baseUrl}/api/v1/userAuth/userLogin`,{
        email:email,
        password:password
      })

      if(responseFromApi.data.userId){
        localStorage.setItem('userId',responseFromApi.data.userId)
        localStorage.setItem('loginToken',responseFromApi.data.token)
        setShowOneLastStep(true)
        setIsOpenRegister(false)
        dispatch(isLoginAction(false))
      }
      else if (responseFromApi.data.result._id){
        dispatch(isLoginAction(false))
        localStorage.setItem('userId',responseFromApi.data.result._id)
        localStorage.setItem('loginToken',responseFromApi.data.token)
        localStorage.setItem("secondStage",true)
        setIsOpenRegister(false)
         navigate("/")
        
      }
      toast.success(' Login Successfull!', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          background: 'rgb(5 150 105)', // Your custom color
          color: '#ffffff', // Text color
          fontWeight: 'bold', // Font weight
        },
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
       
    }
    catch(e){
      console.log(e)
      toast.error( e.response.data.msg, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    }

   }
    
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className=" fixed inset-0 overflow-y-auto text-center z-30"
        onClose={() =>{
          dispatch(isLoginAction(false))
        }}
      >
        <div className="min-h-screen px-4">
        
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            ​
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="
            inline-block w-full max-w-lg
            p-10 overflow-hidden text-left 
            align-middle transition-all transform 
            bg-white shadow-xl rounded-2xl "
            >
              
             {   showPrompt === true 
             ?   
                <div className="overflow-hidden bg-white mx-auto ">
                <div className="text-center mb-6">
                  <h6 className="text-2xl font-bold text-black">Forgot Password ?</h6>
                </div> 
                </div>
              : 

                 <div className="overflow-hidden bg-white mx-auto ">
                <div className="text-center mb-6">
                  <h2 className="text-3xl font-bold text-black">Login</h2>
                  <p className="text-sm md:text-base text-gray-500 mt-2 mb-8 sm:mb-10">
                    Login with your email and password
                  </p>
                </div>
                </div>
              }

                      {showPrompt && (
                          <div className="prompt ">
                            <div className="grid m-3">
                          <label
                            htmlFor="forgot"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Email
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                  <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                              </span>
                            </div>
                            <input
                              className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="forgot"
                              value={email}
                              placeholder="Email"
                              onChange={(e) => setEmail(e.target.value)}
                              type="email"
                            />
                          </div>
                        
                        </div>
                          
                            <div className="flex justify-around space-x-4 px-3">
                            <button onClick={handlePromptSubmit} className="bg-emerald-500 text-white rounded-md py-2 px-4 w-2/4">Reset Password</button>
                            <button onClick={() => setShowPrompt(false)} className="bg-red-500 text-white rounded-md py-2 px-4 w-2/4">Cancel</button>
                            </div>
                          </div>
                        )}
            

            {showPrompt === false ?  
                   <Formik
                  validationSchema={LoginSchema}
                  initialValues={{
                    password: "",
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    loginUser(values)
                    // await new Promise((r) => setTimeout(r, 500));
                    // alert(JSON.stringify(values, null, 2));
                  }}
                >
                  {({ errors, touched, isValidating }) => (
                    <Form className="flex flex-col justify-center">
                      <div className="grid grid-cols-1 gap-5">
                        <div className="grid">
                          <label
                            htmlFor="email"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Email
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                  <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                              </span>
                            </div>
                            <Field
                              className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="email"
                              name="email"
                              placeholder="Email"
                              
                              type="email"
                            />
                          </div>
                          {errors.email && touched.email && (
                            <span className="text-red-400 text-sm mt-2">
                              {errors.email}
                            </span>
                          )}
                        </div>
                       


                        <div className="grid">
                          <label
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            htmlFor="password"
                          >
                            Password
                          </label>
                          <div className="relative">

                          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                              </span>
                            </div>
  
    <Field
      className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
      id="password"
      name="password"
      placeholder="Password"
      type={showPassword ? 'text' : 'password'}
    />
      <div className="absolute inset-y-0 right-3 pl-3 flex items-center cursor-pointer">
      
      <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="cursor-pointer"
        >
          {showPassword ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
          </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
          </svg>
          )}
        </button>
      </span>
    </div>
  </div>
  {errors.password && touched.password && (
    <span className="text-red-400 text-sm mt-2">
      {errors.password}
    </span>
  )}
</div>

                       
                      <div className="App">
                        <div className="flex items-center text-black justify-between">
                          <div className="flex ms-auto">
                            <button
                              type="button"
                              className="text-end text-sm text-heading ps-3 underline hover:no-underline focus:outline-none"
                              onClick={handleForgotPassword}
                            >
                              Forgot password?
                            </button>
                          </div>
                        </div>

                        
                       </div>
                        <button
                          type="submit"
                          className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
                        >
                          Login
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>:"" }
               
               
               {
                showPrompt === true ? " " :  <div className="text-center text-sm text-gray-900 mt-4">
                <div className="text-gray-500 mt-2.5">
                  Don't Have a Account ?
                  <button
                    onClick={() => {
                      setIsOpenRegister(true);
                      dispatch(isLoginAction(false));
                    }}
                    className="text-gray-800 hover:text-emerald-500 font-bold mx-2"
                  >
                    Register
                  </button>
                </div>
              </div>
               }
               
            </Dialog.Panel>
          </Transition.Child>
          
         
        </div>
      </Dialog>
    </Transition>
  );
};

export default Login;
