import React, { useEffect } from "react";
 import aboutUs from "../assets/img/about-us.png";
import a1 from "../assets/img/a1.jpg"
import a2 from "../assets/img/a2.jpg"
 import banner from '../assets/img/aboutus.jpg';
import aboutVegetables from "../assets/img/about-vegetables.png";
import bottomBanner from "../assets/img/bottom banner.png"
import men1 from "../assets/img/men-1.png";
import men2 from "../assets/img/men-2.png";
import men3 from "../assets/img/men-3.png";
import women1 from "../assets/img/women-1.png";
import women2 from "../assets/img/women-2.png";
import women3 from "../assets/img/women-3.png";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // bg-page-header-bg
  return (
    <div className="bg-gray-50">
      <div className="flex justify-center py-10 lg:py-20   bg-indigo-100 w-full bg-cover bg-no-repeat bg-bottom " style={{ backgroundImage: `url(${banner})` }}>
        <div className="flex mx-auto w-full max-w-screen-2xl px-3 sm:px-10">
          <div className="w-full flex justify-center flex-col relative">
            <h2 className="text-xl md:text-3xl lg:text-4xl font-bold text-center text-gray-50">
              About US
            </h2>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="max-w-screen-2xl mx-auto lg:py-20 py-10 px-4 sm:px-10">
          <div className="grid grid-flow-row lg:grid-cols-2 gap-4 lg:gap-16 ">
            <div>
              <h4 className="text-xl lg:text-3xl mb-2  font-semibold">
              Welcome to Shreeraj Gifts Private Limited - Your Trusted Corporate Gifting Reseller Partner
              </h4>
              <div className="mt-3 text-base opacity-90 leading-7">
                <p className="mb-4">
                At Shreeraj Gifts, we take pride in being a leading corporate 
                gifting wholesaler that specializes in providing businesses 
                with a comprehensive and innovative range of gifting solutions. 
                With a firm belief in the power of thoughtful gestures and meaningful 
                relationships, we offer a curated selection of high-quality products 
                designed to leave a lasting impact on your clients, partners, and employees.
                </p>
                 <h4>Our Commitment</h4>
                <p>
                  Our mission is to be your go-to partner in enhancing your corporate gifting strategy. 
                  We understand that every gift you give is a reflection of your brand's values and appreciation, 
                  and we are dedicated to ensuring that your gifting experience is seamless, remarkable, 
                  and tailored to your unique requirements.

                </p>
              </div>
              <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-2 xl:gap-6 mt-8">
                <div className="p-8 bg-indigo-50 shadow-sm rounded-lg">
                  <span className="text-3xl block font-extrabold  mb-4 text-gray-800">
                    10K
                  </span>
                  <h4 className="text-lg font-bold mb-1">Explore Our Range</h4>
                  <p className="mb-0 opacity-90 leading-7">
                  Unveil a world of possibilities with our diverse collection of listed products, tailored for your corporate gifting needs. From timeless classics to innovative gems, we have every product to elevate your gifting game.
                  </p>
                </div>
                <div className="p-8 bg-indigo-50 shadow-sm rounded-lg">
                  <span className="text-3xl block font-extrabold  mb-4 text-gray-800">
                    8K
                  </span>
                  <h4 className="text-lg  font-bold mb-1">Lovely Customer</h4>
                  <p className="mb-0 opacity-90 leading-7">
                  
                  we redefine online corporate gifting. 
                  Our meticulously curated selection ensures excellence in every detail. 
                  Experience the brilliance of gifting in 8K, where every gesture is finely pixelated for your valued relationships
                  </p>
                </div>
              </div>
            </div>
            <div className="mt-10 lg:mt-0 flex justify-center ">
              <span
                style={{
                  boxSizing: "border-box",
                  display: "inline-block",
                  overflow: "hidden",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: "1",
                  border: "0",
                  margin: "0",
                  padding: "0",
                  position: "relative",
                  maxWidth: "80%",
                  
                }}
              >
                <img
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    height:"100%"
                  }}
                  className="rounded-3xl"
                  src={a2}
                />
              </span>
            </div>
          </div>
          <div className="mt-10 lg:mt-16 text-base opacity-90 leading-7">
            <h4> Why Choose Us?</h4>
            <p className="mb-4 mt-3">
            1. Diverse Product Catalog: Our extensive range of products is carefully 
            curated to encompass a wide spectrum of gifting possibilities. From elegant
             executive accessories like notepads, bags, pen, bottles to innovative tech gadgets, 
             from eco-friendly options to timeless classics, we offer an array of choices to suit 
             every occasion and recipient.
            </p>
            <p>
            2. Uncompromising Quality: We hold quality in the 
            highest regard. Our products are sourced from reputable manufacturers 
            and suppliers who meet stringent quality standards. This ensures that the 
            gifts you choose from us are not only visually 
            stunning but also durable and reflective of your commitment to excellence.
            </p>
            <p>
            3. Customization Expertise: We understand that personalization is key to making a 
            lasting impression. With our expert customization services, you can seamlessly integrate 
            your brand identity into each gift. 
            From embossed logos to custom packaging, we help you create gifts that are unmistakably yours.
            </p>
            <p>
            4. Competitive Wholesale Pricing: We are committed to providing competitive 
            wholesale pricing without compromising on quality. Our bulk purchasing options 
            enable you to maximize your budget while still offering premium gifts that resonate.
            </p>
            <p>
            5. Sustainability Focus: As responsible corporate citizens, we offer a range of 
            eco-conscious gifting options. These options allow you to align your gifting 
            strategy with your company's commitment to sustainability, making a positive impact 
            on both recipients and the planet.
            </p>
            <p>
            6. Customer-Centric Approach: Our clients are at the core of our business. 
            We prioritize clear communication, responsive customer service, and a collaborative 
            approach to ensure that your experience with us is exceptional at every stage of the process.
            </p>
          </div>
          <div className="mt-10 lg:mt-12 flex flex-col sm:grid gap-4">
            <span
              style={{
                boxSizing: "border-box",
                display: "inline-block",
                overflow: "hidden",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: "1",
                border: "0",
                margin: "0",
                padding: "0",
                position: "relative",
                maxWidth: "100%",
              }}
            >
              <span
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  width: "initial",
                  height: "initial",
                  background: "none",
                }}
              >
                <img
                  style={{
                    display: "block",
                    maxWidth: "100%",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    boxSizing: "border-box",
                  }}
                  className="block rounded-lg"
                  src={bottomBanner}
                />
              </span>
            </span>
          </div>
        </div>
        {/* <div className="bg-gray-50 lg:py-20 py-10">
          <div className="max-w-screen-2xl mx-auto px-3 sm:px-10">
            <div className="relative flex flex-col sm:flex-row sm:items-end justify-between mb-8">
              <div className="max-w-2xl">
                <h3 className="text-xl lg:text-3xl mb-2  font-semibold">
                  Our Founder
                </h3>
                <p className="mt-2 md:mt-3 font-normal block text-base">
                  We’re impartial and independent, and every day we create
                  distinctive, world-class reintermediate backend supply
                  programmes.
                </p>
              </div>
            </div>
            <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-6 xl:gap-x-8 ">
              <div className="max-w-sm">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      className="block rounded-lg"
                      style={{
                        display: "bolck",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: "1",
                        border: "0",
                        margin: "0",
                        padding: "0",
                      }}
                      src={men1}
                    />
                  </span>
                </span>
                <div className="py-4">
                  <h5 className="text-lg font-semibold ">Niamh Shea</h5>
                  <span className="opacity-75 text-sm">
                    Co-founder &amp; Executive
                  </span>
                </div>
              </div>
              <div className="max-w-sm">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      className="block rounded-lg"
                      style={{
                        display: "bolck",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: "1",
                        border: "0",
                        margin: "0",
                        padding: "0",
                      }}
                      src={women1}
                    />
                  </span>
                </span>
                <div className="py-4">
                  <h5 className="text-lg font-semibold ">Orla Dwyer</h5>
                  <span className="opacity-75 text-sm">Chief Executive</span>
                </div>
              </div>
              <div className="max-w-sm">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      className="block rounded-lg"
                      style={{
                        display: "bolck",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: "1",
                        border: "0",
                        margin: "0",
                        padding: "0",
                      }}
                      src={men2}
                    />
                  </span>
                </span>
                <div className="py-4">
                  <h5 className="text-lg font-semibold ">Danien James</h5>
                  <span className="opacity-75 text-sm">
                    Co-founder, Chairman
                  </span>
                </div>
              </div>
              <div className="max-w-sm">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      className="block rounded-lg"
                      style={{
                        display: "bolck",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: "1",
                        border: "0",
                        margin: "0",
                        padding: "0",
                      }}
                      src={women2}
                    />
                  </span>
                </span>
                <div className="py-4">
                  <h5 className="text-lg font-semibold ">Dara Frazier</h5>
                  <span className="opacity-75 text-sm">
                    Chief Strategy Officer
                  </span>
                </div>
              </div>
              <div className="max-w-sm">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      className="block rounded-lg"
                      style={{
                        display: "bolck",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: "1",
                        border: "0",
                        margin: "0",
                        padding: "0",
                      }}
                      src={women3}
                    />
                  </span>
                </span>
                <div className="py-4">
                  <h5 className="text-lg font-semibold ">Glenda Arvidson</h5>
                  <span className="opacity-75 text-sm">HR Officer</span>
                </div>
              </div>
              <div className="max-w-sm">
                <span
                  style={{
                    boxSizing: "border-box",
                    display: "inline-block",
                    overflow: "hidden",
                    width: "initial",
                    height: "initial",
                    background: "none",
                    opacity: "1",
                    border: "0",
                    margin: "0",
                    padding: "0",
                    position: "relative",
                    maxWidth: "100%",
                  }}
                >
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "block",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      maxWidth: "100%",
                    }}
                  >
                    <img
                      className="block rounded-lg"
                      style={{
                        display: "bolck",
                        maxWidth: "100%",
                        width: "initial",
                        height: "initial",
                        background: "none",
                        opacity: "1",
                        border: "0",
                        margin: "0",
                        padding: "0",
                      }}
                      src={men3}
                    />
                  </span>
                </span>
                <div className="py-4">
                  <h5 className="text-lg font-semibold ">Melvin Davis</h5>
                  <span className="opacity-75 text-sm">Lead Developer</span>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default About;
