import React,{useState,useEffect} from "react";
import { Link, Outlet,useNavigate,NavLink } from "react-router-dom";
import { UserMenu } from "../../fakeData/UserMenu";
import ReactModal from 'react-modal';
import axios from "axios"
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const User = () => {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

  useEffect(()=>{
    const safeRoute =()=>{
        const loginToken = localStorage.getItem("loginToken")
        const userId = localStorage.getItem("userId")
        const secondStage = localStorage.getItem("secondStage")
        if(loginToken=== null || userId === null || secondStage === null){
          navigate("/")
        }
      }
      safeRoute()
},[])
const [clickedMenuItem, setClickedMenuItem] = useState(""); 


  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [deletePopup,setDeletePopup] = useState(false)
  const toggleModal = () => {
    setIsModalOpen(prevState => !prevState);
  };
  const onRequestClose = () => {
    setIsModalOpen(false);
  };
  const handleLogout = () => {
    localStorage.removeItem("loginToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("secondStage");
    navigate("/");
    window.location.reload();
  };

  const handleDeleteAccount= async ()=>{
    try{
      const headers = {
        authorization: localStorage.getItem("loginToken"),
      };
      const deleteApiResponse = await axios.delete(`${baseUrl}/api/v1/userAuth/userDeleteAccount`,{
        headers,
        data:{
          "_id":localStorage.getItem("userId")
        }
      })
      if(deleteApiResponse){
      
        toast.success(`Account Deleted Successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
        }
        )
        localStorage.clear();
        navigate("/");
        window.location.reload();
      }
    }
    catch(e){
      console.log(e)
      // alert("error occured")

    }
  }
  const navigate = useNavigate()

  return (
    <div className="bg-gray-50">
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="py-10 lg:py-12 flex flex-col lg:flex-row w-full">
          <div className="flex-shrink-0 w-full lg:w-80 mr-7 lg:mr-10 xl:mr-10">
            <div className="bg-white p-4 sm:p-5 lg:p-8 rounded-md sticky top-32 group">
            
         {UserMenu.map((data,index)=>(
        

         
          <Link to = {data.path} 
          className={`text-sm !no-underline font-medium w-full ${
            clickedMenuItem === data.title ? "!text-emerald-600" : "!text-black"
          }`}
          onClick={() => setClickedMenuItem(data.title)}
          >
            <span key={index} 
             className={`group p-2 my-2 flex text-black items-center rounded-md hover:text-emerald-600 w-full ${
              clickedMenuItem === data.title ? "!text-emerald-600" : "!text-black"
            }`}
            onClick={() => setClickedMenuItem(data.title)} 

            >
              {data.icon}
            <Link to={data.path}
                className={`text-sm  ml-3 !no-underline font-medium w-full group-hover:text-emerald-600 ${
                  clickedMenuItem === data.title
                    ? "!text-emerald-600"
                    : "!text-black"
                }`}
   
            >
            {data.title}
            </Link>
          </span>
          </Link>



          
         ))}
          <div className=" text-black hover:text-emerald-600 cursor-pointer"  onClick={() => {
                 setDeletePopup(true)
              }}>
          <span className="p-2 flex  items-center rounded-md  w-full">
           <span className="">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
               <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z"/>
               <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z"/>
              </svg></span>
            <button
              className="inline-flex  ml-3 items-center justify-between text-sm font-medium w-full"
              // onClick={() => {
              //    setDeletePopup(true)
              // }}
            >
              Delete Account
            </button>
          </span>
          </div>
     
            
              <span className=" cursor-pointer p-2 flex  items-center rounded-md hover:bg-gray-50 w-full text-black hover:text-emerald-600" onClick={() => setIsPopupOpen(true)}>
                <span className="">
                  <svg
                    stroke="currentColor"
                    fill="currentColor"
                    strokeWidth="0"
                    viewBox="0 0 512 512"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      d="M336 112a80 80 0 00-160 0v96"
                    ></path>
                    <rect
                      width="320"
                      height="272"
                      x="96"
                      y="208"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="32"
                      rx="48"
                      ry="48"
                    ></rect>
                  </svg>
                </span>
                <button className="inline-flex  ml-3  items-center justify-between text-sm font-medium w-full" 
                  // onClick={() => setIsPopupOpen(true)}
                >
                  Logout
                </button>
              </span>
       {/* logout popup */}
      <Popup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        modal
        contentStyle={{
          maxWidth: '400px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {close => (
          <div>
            <p className="text-center font-bold ">Are You Sure You Want To Logout?</p>
            <div className="mt-4 flex justify-between">
              <button
                className="flex-grow bg-emerald-500 text-white py-2 rounded-md mr-2 hover:bg-emerald-600"
                onClick={() => {
                  handleLogout();
                  close();
                }}
              >
                Yes
              </button>
              <button
                className="flex-grow bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400"
                onClick={() => close()}
              >
                No
              </button>
            </div>
          </div>
        )}
      </Popup>

          {/* delete account */}
          <Popup
        open={deletePopup}
        onClose={() => setDeletePopup(false)}
        modal
        contentStyle={{
          maxWidth: '400px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {close => (
          <div>
            <p className="text-center font-bold ">Are You Sure You Want To Delete Your Account? </p>
            <div className="mt-4 flex justify-between">
              <button
                className="flex-grow bg-emerald-500 text-white py-2 rounded-md mr-2 hover:bg-emerald-600"
                onClick={() => {
                  handleDeleteAccount()
                  close();
                }}
              >
                Yes
              </button>
              <button
                className="flex-grow bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400"
                onClick={() => close()}
              >
                No
              </button>
            </div>
          </div>
        )}
      </Popup>
            </div>
          </div>
          <div className="w-full bg-white mt-4 lg:mt-0 p-4 sm:p-5 lg:p-8 rounded-md overflow-hidden">
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
