import React, { Fragment,useState,useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { isLoginAction } from "../../store/reducers/isOpenSlice";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import plus from "../../assets/img/240_F_481134373_0W4kg2yKeBRHNEklk4F9UXtGHdub3tYk.jpg"

import axios from "axios"
const OneLastStep = ({ showOneLastStep,setShowOneLastStep }) => {
  let navigate = useNavigate();

  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

  const fileInputRef2 = useRef(null);
  const dispatch = useDispatch()
  const [selectedImage,setSelectedImage]= useState(plus)
  // "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1wwaPU6CL-aQEa3w7xm3lQMgyI6ld2pnyeLeprDmWffeIvmUQ2rprHT4PuU0hQ9LzEUg&usqp=CAU"
  const handleImageClick = () => {
		fileInputRef2.current.click();
	};

  const [selectedFile, setSelectedFile] = useState('')

  const handleImageUpload = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
      setSelectedFile(file)
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
      
		
  }
 

  const SignupSchema = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required!"),
    companyName: Yup.string().required("Company Name is required!"),
    companyAddress: Yup.string().required("Company Address is required!"),
    gstin: Yup.string(),
    website :Yup.string()
   
  });

  const createUserProfile= async (data)=>{
    const {fullName,companyName,companyAddress,gstin,image,website} = data

    const cleanedName = fullName.replace(/\s+/g, '');
    const cleanedCompanyName = companyName.replace(/\s+/g, '');
    const cleanedCompanyAddress = companyAddress.replace(/\s+/g, '');
    
    if(cleanedName===""||cleanedCompanyName===""|| cleanedCompanyAddress===""){
      toast.warn('Input Fields Cannot Be Empty', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    }
    
    // else if(selectedImage===plus){
    //   toast.warn(' Please Uplaod The Image', {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //     });
    // }
    else if (fullName.length>15){
      toast.warn('Name Cannot Be Greater Than 15 Characters', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    }
    else{
      try{
        const responseFromApi = await  axios.post(`${baseUrl}/api/v1/profile/createUserProfile`,{
              "name": fullName,
              companyName,
              companyAddress,
              "authId": localStorage.getItem("userId"),
              "gst": gstin,
              "companyLogo": "",
              "website":website,
        })
       
         if(selectedFile!==""){
          const formData = new FormData();
          formData.append("logo", selectedFile);
          const headers = {
              authorization:localStorage.getItem("loginToken")
          } 
            const imgResponeFromApi = await axios.post(`${baseUrl}/api/v1/profile/addCompanyLogo`,  formData,{
              headers,
            })
         }
         if(responseFromApi){
          localStorage.setItem("secondStage",true)
        }
         dispatch(isLoginAction(false));
         setShowOneLastStep(false)
         toast.success('Complete Account Created successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
          });
          toast.success('Shreeraj Gifts Teams Has To Verify Your Account To Become a Distributor', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: {
              background: 'rgb(5 150 105)', // Your custom color
              color: '#ffffff', // Text color
              fontWeight: 'bold', // Font weight
            },
            });
  
        navigate("/")
      }
      catch(e){
         console.log(e)
         toast.error('Error occured while Completing Your Account', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
         
          });
  
      }

    }
    
   
  }


  return (
    <Transition appear show={showOneLastStep} as={Fragment}>
      <Dialog
        as="div"
        className=" fixed inset-0 overflow-y-auto text-center z-30"
        onClose={() =>false}
      >
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            ​
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="
            inline-block w-full max-w-lg
            p-10 overflow-hidden text-left 
            align-middle transition-all transform 
            bg-white shadow-xl rounded-2xl "
            >
              <div className="overflow-hidden bg-white mx-auto ">
                <div className="text-center mb-6">
                  <h2 className="text-3xl font-bold text-black">
                    {" "}
                    One Last Step
                  </h2>
                  <p className="text-sm md:text-base text-gray-500 mt-2 mb-8 sm:mb-10">
                    We need some more information to create your account
                  </p>
                </div>
                <div className='inline py-2 m-3 flex justify-center max-w-80'>
												<img
													src={selectedImage}
													alt='Selected'
													onClick={handleImageClick}
													style={{ cursor: 'pointer' }}
													className='w-[200px] h-[200px] rounded border-1 border-black'
												/>
												<input
													type='file'
													ref={fileInputRef2}
													accept='image/*'
													onChange={handleImageUpload}
													style={{ display: 'none' }}
													key={selectedImage} // Add this key prop to reset the file input
												/>
											</div>	

                <Formik
                  validationSchema={SignupSchema}
                  initialValues={{
                    fullName: "",
                    companyName: "",
                    companyAddress: "",
                    gstin: "",
                    website:""
                  
                  }}
                  onSubmit={async (values) => {
                    await createUserProfile(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    isValidating,
                    setFieldValue,
                  }) => (
                    <Form className="flex flex-col justify-center">
                      <div className="grid grid-cols-1 gap-5">

                    

                        <div className="grid">
                          <label
                            htmlFor="fullName"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Full Name
                          </label>
                          <Field
                            className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="fullName"
                            name="fullName"
                            placeholder="Full Name"
                            type="text"
                          />
                          {errors.fullName && touched.fullName && (
                            <span className="text-red-400 text-sm mt-2">
                              {errors.fullName}
                            </span>
                          )}
                        </div>
                        <div className="grid">
                          <label
                            htmlFor="companyName"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Company Name
                          </label>
                          <Field
                            className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="companyName"
                            name="companyName"
                            placeholder="Company Name"
                            type="text"
                          />
                          {errors.companyName && touched.companyName && (
                            <span className="text-red-400 text-sm mt-2">
                              {errors.companyName}
                            </span>
                          )}
                        </div>
                        <div className="grid">
                          <label
                            htmlFor="companyAddress"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Company Address
                          </label>
                          <Field
                            className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="companyAddress"
                            name="companyAddress"
                            placeholder="Company Address"
                            type="text"
                          />
                          {errors.companyAddress && touched.companyAddress && (
                            <span className="text-red-400 text-sm mt-2">
                              {errors.companyAddress}
                            </span>
                          )}
                        </div>
                        <div className="grid">
                            <label
                              htmlFor="fullName"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              Website Address (Optional)
                            </label>
                            <Field
                              className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="website"
                              name="website"
                              placeholder="Website"
                              type="text"
                             
                            />
                          </div>
                        <div className="grid">
                          <label
                            htmlFor="gstin"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            GSTIN (Optional)
                          </label>
                          <Field
                            className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="gstin"
                            name="gstin"
                            placeholder="GSTIN"
                            type="text"
                          />
                          {/* ... (existing code) */}
                        </div>
                        <button
                          type="submit"
                          className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
                        >
                          Create Account
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </Dialog.Panel>
          </Transition.Child>
          
        </div>
      </Dialog>
    </Transition>
  );
};
export default OneLastStep;
