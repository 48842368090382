import React, { Fragment,useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { isLoginAction } from "../../store/reducers/isOpenSlice";
import { useDialog } from '../../context/dailogContext';
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = ({ isOpen, setIsOpenRegister,setShowOneLastStep }) => {
  let navigate = useNavigate();
  const dialog = useDialog();
  const handleOpenDialog = () => {
    dialog.openDialog();
  };

  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

  const handleCloseDialog = () => {
    dialog.closeDialog();
  };
  const handleWheel = (event) => {
    event.target.blur(); // Blur the input element
  };
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const SignupSchema = Yup.object().shape({
    fullName: Yup.string().required("Phone Number Is Required!"),
    password: Yup.string().required("Password is required!"),
    email: Yup.string().email("Invalid email").required("Email is required!"),
  });

  const signUpUser= async (data)=>{
    const {fullName,password,email} = data
    const cleanedPassword = password.replace(/\s+/g, '');
    const cleanedFullName = fullName.toString().replace(/\s+/g, '');
    const cleanedEmail = email.replace(/\s+/g, '');
     
    // fullName.toString().length>10 || fullName.toString().length<10
    if(cleanedEmail===""||cleanedFullName===""|| cleanedPassword===""){
      toast.warn('Input Fields Cannot Be Empty', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });  
    }
    else if(fullName.toString().length!==10){
     
      toast.warn('Please Provide Valid Mobile No', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    }
    else if(password.length < 8){
      toast.warn('Password Must Be Atleast 8 Characters', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    else{
      try{
        const responseFromApi = await  axios.post(`${baseUrl}/api/v1/userAuth/userSignUp`,{
          phone:fullName.toString(),
          email:email,
          password:password
        })
        toast.success('Account created successfully', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
          });
        localStorage.setItem('userId',responseFromApi.data.result._id)
        localStorage.setItem('loginToken',responseFromApi.data.token)
        setShowOneLastStep(true)
        setIsOpenRegister(false)
      }
      catch(e){
       
       if(e.response.status == 400){
        
        toast.warn('User Already Exists Please Login', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          
          });
    }
    else{
        console.log(e)
    }
      }

    }
    
    
  }


  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className=" fixed inset-0 overflow-y-auto text-center z-30"
        static = {true}
         onClose= {()=>{
          setIsOpenRegister(false)
         }}
      
      >
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            ​
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className="
            inline-block w-full max-w-lg
            p-10 overflow-hidden text-left 
            align-middle transition-all transform 
            bg-white shadow-xl rounded-2xl "
            >
              <div className="overflow-hidden bg-white mx-auto ">
                <div className="text-center mb-6">
                  <h2 className="text-3xl font-bold text-black"> Signing Up</h2>
                  <p className="text-sm md:text-base text-gray-500 mt-2 mb-8 sm:mb-10">
                    Create an account with email
                  </p>
                </div>

                <Formik
                  validationSchema={SignupSchema}
                  initialValues={{
                    fullName: "",
                    password: "",
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    await signUpUser(values)
                  
                  }}
                >
                  {({ errors, touched, isValidating }) => (
                    <Form className="flex flex-col justify-center">
                      <div className="grid grid-cols-1 gap-5">
                        <div className="grid">
                          <label
                            htmlFor="email"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Phone Number
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                {}+91 
                              </span>
                            </div>

                            <Field
                              className="py-2 pl-12 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12 no-scroll-input"
                              id="fullName"
                              name="fullName"
                              placeholder="91234567890"
                              type="number"
                              onWheel={handleWheel}
                              onInput={(e) => {
                                e.target.value = e.target.value.replace(/\D/g, '').slice(0, 10);
                              }}
                              onKeyPress={(e) => {
                                if (e.key === "-" || e.key === "e") {
                                  e.preventDefault();
                                }
                              }}
                              min="0"
                              maxLength="10"
                            />
                          </div>
                          {errors.fullName&&errors.fullName && (
                            <span className="text-red-400 text-sm mt-2">
                              {errors.fullName}
                            </span>
                          )}
                        </div>
                        <div className="grid">
                          <label
                            htmlFor="email"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                          >
                            Email
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                                  <polyline points="22,6 12,13 2,6"></polyline>
                                </svg>
                              </span>
                            </div>
                            <Field
                              className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="email"
                              name="email"
                              placeholder="Email"
                              type="email"
                            />
                          </div>
                          {errors.email && touched.email&&(
                            <span className="text-red-400 text-sm mt-2">
                              {errors.email}
                            </span>
                          )}
                        </div>
                        {/* <div className="grid">
                          <label
                            className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            htmlFor="password"
                          >
                            Password
                          </label>
                          <div className="relative">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                              </span>
                            </div>
                            <Field
                              className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="password"
                              name="password"
                              placeholder="Password"
                              type="password"
                            />
                          </div>
                          {errors.password && touched.password&&(
                            <span className="text-red-400 text-sm mt-2">
                              {errors.password}
                            </span>
                          )}
                        </div> */}
                        <div className="grid">
  <label
    className="block text-gray-500 font-medium text-sm leading-none mb-2"
    htmlFor="password"
  >
    Password
  </label>
  <div className="relative">
  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                              <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
                                <svg
                                  stroke="currentColor"
                                  fill="none"
                                  strokeWidth="2"
                                  viewBox="0 0 24 24"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  height="1em"
                                  width="1em"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <rect
                                    x="3"
                                    y="11"
                                    width="18"
                                    height="11"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                                </svg>
                              </span>
                            </div>
    
    <Field
      className="py-2 pl-10 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
      id="password"
      name="password"
      placeholder="Password"
      type={showPassword ? 'text' : 'password'}
    />
    <div className="absolute inset-y-0 right-4  pl-3 flex items-center cursor-pointer">
      <span className="text-gray-800 focus-within:text-gray-900 sm:text-base">
        {/* Toggle icon */}
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="cursor-pointer"
        >
          {showPassword ? (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
          </svg>
          ) : (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-eye-slash" viewBox="0 0 16 16">
            <path d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z"/>
            <path d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z"/>
            <path d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z"/>
          </svg>
          )}
        </button>
      </span>
    </div>
  </div>
  {errors.password && touched.password && (
    <span className="text-red-400 text-sm mt-2">{errors.password}</span>
  )}
</div>

                        <div className="flex items-center text-black justify-between">
                          <div className="flex ms-auto">
                            {/* <button
                              type="button"
                              className="text-end text-sm text-heading ps-3 underline hover:no-underline focus:outline-none"
                            >
                              Forgot password?
                            </button> */}
                          </div>
                        </div>
                        <button
                          type="submit"
                          className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
                        >
                          Register
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>

                <div className="text-center text-sm text-gray-900 mt-4">
                  <div className="text-gray-500 mt-2.5">
                    Already Have a Account ?
                    <button
                      onClick={() => {
                        setIsOpenRegister(false);
                        dispatch(isLoginAction(true));
                        
                      }}
                      className="text-gray-800 hover:text-emerald-500 font-bold mx-2"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
            </Dialog.Panel>
          </Transition.Child>
         
        </div>
      </Dialog>
    </Transition>
  );
};

export default Register;
