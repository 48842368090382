import "./App.css";
import Header from "./components/header/Header";
import Navigation from "./components/navigation/Navigation";
import About from "./pages/About";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import { useDispatch } from "react-redux";
import { DialogProvider } from './context/dailogContext';

import Contact from "./pages/Contact";
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Pdf from "./components/pdf/pdf";

import Footer from "./components/footer/Footer";
import MobileApp from "./components/mobileApp/MobileApp";
import Product from "./components/product/Product";
import Checkout from "./components/checkout/Checkout";
import Order from "./components/order/Order";
import FooterHeader from "./components/footerHeader/FooterHeader";
import SideBar from "./components/sideBar/SideBar";
import DrawerCart from "./components/drawer/Drawer";
import Search from "./components/search/Search";
import Login from "./components/login/Login";
import Register from "./components/register/Register";
import { Fragment, useState,useEffect } from "react";
import User from "./components/user/User";
import Dashboard from "./components/dashboard/Dashboard";
import ChangePassword from "./components/changePassword/ChangePassword";
import MyOrders from "./components/myOrders/MyOrders";
import UpdateProfile from "./components/updateProfile/UpdateProfile";
import Offers from "./pages/Offers";
import CartContext from './context/cartContext.js';
import OneLastStep from "./components/onelaststep.js/onelaststep";
import { isLoginAction } from "./store/reducers/isOpenSlice";
import Address from "./components/address/address";
import axios from "axios"
import OrderDetails from "./components/orderDetails/orderDetails";
import { ProtectedRoute } from "./components/protectedRoute.js/protect";
import Catalog1 from "./components/catalog1/catalog1";
import Catalog2 from "./components/catalog2/catalog2";

function App() {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
 const dispatch= useDispatch()
  const [isOpenRegister, setIsOpenRegister] = useState(false);
  const [showOneLastStep,setShowOneLastStep] = useState(false)
  const [cartList, setCartList] = useState([]);
  const [reload,setReload] = useState(false)

  useEffect(()=>{
    const loginToken = localStorage.getItem("loginToken")
    const userId = localStorage.getItem("userId")
    const secondStage = localStorage.getItem("secondStage")
    
  
 



    if(loginToken!== null && userId !== null && secondStage === null){
      dispatch(isLoginAction(false));
      setIsOpenRegister(false)
      setShowOneLastStep(true)
    }

  },[])


 



  useEffect(()=>{
    const fetchCartList = async ()=>{

      if (navigator.onLine) {
        // alert('You are online.');
      } else {
        toast.warn("You Are Offline!", {
          position: "top-center",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "colored",
          });
      }
      if(localStorage.getItem("secondStage")){
        try{
          const headers = {
            authorization: localStorage.getItem('loginToken') 
          }
          const cartListResponse = await axios.get(`${baseUrl}/api/v1/cart/fetchCartDetails`,{
            headers,
          })
          setCartList(cartListResponse.data)
        }
        catch(e){
          console.log(e)
        }
      }
    }
    fetchCartList()

  },[reload])

  const removeAllCartItems = () => {
    setCartList([]);
  };



  const incrementCartItemQuantity = async(id,) => {
    const productObject = cartList.find(eachCartItem => eachCartItem.productId === id);
   if(productObject){
      try{
        const headers={
        authorization: localStorage.getItem('loginToken') 
        }
          const cartUpdateResponse = await axios.post(`${baseUrl}/api/v1/cart/updateCart`,{
            "productId": id,
            "quantity":  1,
            "_id": localStorage.getItem('userId') // user_id
        },{
          headers,
        })
        if(cartUpdateResponse){
          setReload(!reload)
        }
      }
      catch(e){
        console.log(e)
  
      }

    }
  };
  const removeCartItem = async(productId) => {

    try{
      const headers={
      authorization: localStorage.getItem('loginToken') 
      }
      const requestBody = {
        "productId": productId,
        "_id": localStorage.getItem('userId') // user_id
       }
      const cartUpdateResponse = await axios.delete(`${baseUrl}/api/v1/cart/emptyCart`,{
        headers,
        data: requestBody,
      })
      
      setReload(!reload)
    }
    catch(e){
      console.log(e)

    }
  };



  const decrementCartItemQuantity = async (id,minOrder) => {
    
    const product = cartList.filter((each)=>{
      if( each.productId === id && each.quantity>=2){
        return each
      }
    }
   )

   

    if(product.length>0 && product[0].quantity > minOrder){
      try{
        const headers={
        authorization: localStorage.getItem('loginToken') 
        }
        const cartUpdateResponse = await axios.post(`${baseUrl}/api/v1/cart/updateCart`,{
            "productId": id,
            "quantity": -1,
            "_id": localStorage.getItem('userId') // user_id
        },{
          headers,
        })
      
        setReload(!reload)
      }
      catch(e){
        console.log(e)
  
      }
    }
    else {
         await removeCartItem(id)
    }

  };
 
  
  const addCartItem = async (product) => {
      try{
        const headers={
        authorization: localStorage.getItem('loginToken') 
        }
        const cartUpdateResponse = await axios.post(`${baseUrl}/api/v1/cart/updateCart`,{
            "productId": product._id,
            "quantity": product.minimumOrder,
            "_id": localStorage.getItem('userId') // user_id
        },{
          headers,
        })
        if (cartUpdateResponse){
        setReload(!reload)}
      }
      catch(e){
        console.log(e)

      }
    }

  return(
  <Fragment>
       <DialogProvider>
          <CartContext.Provider
    value={{
      cartList,
      addCartItem,
      removeCartItem,
      incrementCartItemQuantity,
      decrementCartItemQuantity,
      removeAllCartItems,
    }}
  >
      <Header />
      <Navigation />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="about-us" element={<About />} />
        <Route path="contact-us" element={<Contact />} />
        <Route path={"product"} element={<Product />} />
        
        <Route path="checkout" element={<Checkout setReload={setReload} />} />
        <Route path="search" element={<Search />} />
        <Route path="order/:id" element={<Order />} />
        <Route path="offer" element={<Offers />} />
        <Route path="user/my-orders/orderDetails/:id" element={<OrderDetails />} />
        <Route path="pdf/:id" element={<Pdf />} /> 
        {/* <Route path="catalog/" element={<Catalog />} /> */}
        <Route path ="catalog1" element ={<Catalog1/>}/>
        <Route path ="catalog2" element ={<Catalog2/>}/>
        <Route path="user" element={<User />}>
          {/* <Route path="dashboard" element={<Dashboard />} /> */}
          <Route path="my-orders" element={<MyOrders />} />
          <Route path="update-profile" element={<UpdateProfile />} />
          <Route path="change-password" element={<ChangePassword />} />
          <Route path ="address" element = {<Address/>} /> 
        </Route>
        <Route path="*" element={<Home />} />
      </Routes>
      <div>
        <FooterHeader />
      </div>
      <div>
        <SideBar />
        <DrawerCart />
        <Login setIsOpenRegister={setIsOpenRegister}  setShowOneLastStep={setShowOneLastStep} />
        <Register
          isOpen={isOpenRegister}
          setIsOpenRegister={setIsOpenRegister}
          setShowOneLastStep={setShowOneLastStep}
        />
        <OneLastStep showOneLastStep={showOneLastStep} setShowOneLastStep={setShowOneLastStep}  />
      </div>
      <div className="w-full">
        <MobileApp />
        <Footer />
      </div>
     </CartContext.Provider>
     </DialogProvider>
    </Fragment>)

}

export default App;
