import React from "react";
import logo from "../../assets/img/mainlogo.png";
import Basket from "../basket/Basket";
import SearchBar from "../searchBar/SearchBar";
import axios from "axios";

import { Fragment } from "react";
import { useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";

import UserProfile from "../userProfile/UserProfile";
import Declaration from "../declaration/Declaration.js";
import { ResourcesData } from "../../fakeData/ResourcesData";
import { isLoginAction } from "../../store/reducers/isOpenSlice";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Navigation() {
  const [CategoriesData, setCategoriesData] = useState([]);
  const [clickedLink, setClickedLink] = useState(null);
  
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  const loginToken = localStorage.getItem("loginToken")
  const userId = localStorage.getItem("userId")
  const secondStage = localStorage.getItem("secondStage")
  const dispatch= useDispatch()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  useEffect(() => {
    const getCategories = async () => {
      try {
        const apiResponse = await axios.get(
          `${baseUrl}/api/v1/products/fetchAllCategories`
        );
        const activeCatageories = apiResponse.data.filter((each)=>{
          if(each.status==="ACTIVE"){
            return each
          }
        })
        setCategoriesData(activeCatageories);
      } catch (e) {
        // alert("error occured while fetching categories data");
        console.log(e)
      }
    };
    getCategories();
  }, []);
  return (
    <div className="sticky bg-gray-100 top-0 z-20">
      <div className="max-w-screen-2xl mx-auto px-3 sm:px-10">
        <div className="h-16 top-bar lg:h-auto flex items-center justify-between py-4 mx-auto">
          <Link
            to="/"
            className="!no-underline mr-5 xl:mr-12  lg:block "
            
            onClick={() => setClickedLink(null)}
          >
            <span
              style={{
                boxSizing: "border-box",
                display: "inline-block",
                overflow: "hidden",
                width: "initial",
                height: "initial",
                background: "none",
                opacity: "1",
                border: "0px",
                margin: "0px",
                padding: " 0px",
                position: "relative",
                maxWidth: "100%",
                alignItems: "center",
              }}
            >
              <span
                style={{
                  boxSizing: "border-box",
                  display: "block",
                  width: "initial",
                  height: "initial",
                  background: "none",
                  opacity: "1",
                  border: "0px",
                  margin: "0px",
                  padding: " 0px",
                  maxWidth: "100%",
                  alignItems: "center",
                }}
              >
                <img
                  className="flex items-center w-[70px] h-12 lg:ml-4"
                  src={logo}
                />
              </span>
            </span>
          </Link>
          <SearchBar />
          <div className="hidden md:hidden md:items-center lg:flex xl:flex absolute inset-y-0 right-0 pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            {/* <Declaration /> */}
            <Basket className="relative px-5 text-emerald-500 text-2xl font-bold"  setClickedLink={setClickedLink} />
            <div className="pl-5">
              <UserProfile    setClickedLink={setClickedLink}/>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden lg:block xl:block bg-white border-b">
        <div className=" items-center justify-between flex mx-auto max-w-screen-2xl px-3 sm:px-10 h-12">
          <div className="inline-flex">
            <Popover className="relative"
            >
              <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center   md:justify-start md:space-x-10">
                  <Popover.Group
                    as="nav"
                    className=" md:flex space-x-10 items-center"
                  >
                    <Popover className="relative">
                      {({close}) => (
                        <>
                          <Popover.Button 
                          onClick={() =>{
                            setClickedLink("categories")
                            setIsPopoverOpen(!isPopoverOpen)
                          }
                            
                          }
                            
                        
                            className={classNames(
                              `group inline-flex items-center py-2  text-sm focus:outline-none text-black ${
                                clickedLink === "categories" ? "!text-emerald-600" : "hover:!text-emerald-600"
                              }  hover:text-emerald-600 `
                            )}
                          >
                            <span className="text-sm " >Categories</span>
                            <ChevronDownIcon
                              className={classNames(
                                "ml-1 h-3 w-3 group-hover:text-emerald-600"
                              )}
                              aria-hidden="true"
                            />
                          </Popover.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                            
                          >
                            <Popover.Panel  className="absolute z-10 -ml-1 mt-1 transform w-max  c-h-60vh bg-white rounded-xl ">
                              <div className="rounded-md shadow-lg ring-1 ring-black ring-opacity-5 overflow-y-scroll scrollbar flex-grow w-full">
                                <div className="flex flex-col w-full max-h-fit bg-white cursor-pointer scrollbar-hide">
                                  <div className="w-full max-h-fit">
                                    {CategoriesData.length > 0 ? (
                                      <div className="relative grid gap-2 p-6">
                                        {CategoriesData.map(
                                          (category, index) => (
                                            <Link
                                              className="!no-underline !text-black group-hover:!text-emerald-500 cursor-pointer "
                                              onClick={() => {
                                                close()
                                                setIsPopoverOpen(false)
                                                setClickedLink("categories")
                                              }
                                              }
                                              to={
                                                "/search?Category=" +
                                                category._id
                                              }
                                            >
                                              <span
                                                key={index}
                                                className=" p-2 flex items-center rounded-md hover:bg-gray-50 w-full hover:text-emerald-600"
                                              >
                                                <div
                                                  className=" inline-flex items-center justify-between w-full hover:text-emerald-600
                                          "
                                                >
                                                  {category.name}
                                                </div>
                                              </span>
                                            </Link>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <div className="relative grid gap-2 p-6">
                                        please wait{" "}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Popover.Panel>
                          </Transition>
                        </>
                      )}
                    </Popover>
                    <Link
                      // className="text-sm !text-black !no-underline hover:!text-emerald-600"
                      className={`text-sm text-black !no-underline ${
                        clickedLink === "about" ? "!text-emerald-600" : "hover:!text-emerald-600"
                      }`}
                      to="/about-us"
                      onClick={() => setClickedLink("about")}
                    >
                      About Us
                    </Link>
                    <Link
                      // className="text-sm !text-black !no-underline hover:!text-emerald-600"
                      className={`text-sm text-black  !no-underline ${
                        clickedLink === "contact-us" ? "!text-emerald-600" : "hover:!text-emerald-600"
                      }`}
                      to="/contact-us"
                      onClick={() => setClickedLink("contact-us")}
                    >
                      {" "}
                      Contact Us
                    </Link>
                    {
                      loginToken === null || userId === null ||  secondStage === null ? " ":
                      <Link
                      // className="text-sm !text-black !no-underline hover:!text-emerald-600"
                      className={`text-sm text-black !no-underline ${
                        clickedLink === "catalog" ? "!text-emerald-600" : "hover:!text-emerald-600"
                      }`}

                      to= "/catalog1"
                      onClick={() => setClickedLink("catalog")}

                    >
                      {" "}
                      Catalog
                    </Link>
                    }
                     {/* <Link
                      className="text-sm !text-black !no-underline hover:!text-emerald-600"
                      to= "/catalog"
                    >
                      {" "}
                      Catalog
                    </Link> */}
                   
                  </Popover.Group>
                </div>
              </div>
            </Popover>
          </div>
          <div className="flex">
            <Link
              to={`${baseUrl}/privacy-policy/`}
              target="_blank"
              // className={` mx-4 py-2 text-sm text-black !no-underline ${
              //   clickedLink === "Privacy Policy" ? "!text-emerald-600" : "hover:!text-emerald-600"
              // }`}

              className=" mx-4 py-2 text-sm cursor-pointer !no-underline !text-black hover:text-emerald-600"
              // onClick={() => setClickedLink("Privacy Policy")}


            >
              Privacy Policy
            </Link>
            <Link
              to={`${baseUrl}/terms-and-conditions/`}
              target="_blank"
              className=" mx-4 py-2 text-sm cursor-pointer !no-underline !text-black hover:text-emerald-600"
              // className={` mx-4 py-2 text-sm text-black !no-underline ${
              //   clickedLink === "Terms & Conditions" ? "!text-emerald-600" : "hover:!text-emerald-600"
              // }`}
              // onClick={() => setClickedLink("Terms & Conditions")}
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Navigation;
