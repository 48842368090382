import React from "react";
import { FooterData } from "../../fakeData/Footer";
import { Link } from "react-router-dom";
import LogoColor from "../../assets/img/mainlogo.png";
import PaymentImg from "../../assets/img/payment-logo.png";
function Footer() {
  return (
    <>
      <div className="hidden relative lg:block mx-auto max-w-screen-2xl py-6 px-3 sm:px-10">
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 mx-auto">
          <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
            <div className="mr-3">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="flex-shrink-0 h-4 w-4 text-emerald-600"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="1" y="3" width="15" height="13"></rect>
                <polygon points="16 8 20 8 23 11 23 16 16 16 16 8"></polygon>
                <circle cx="5.5" cy="18.5" r="2.5"></circle>
                <circle cx="18.5" cy="18.5" r="2.5"></circle>
              </svg>
            </div>
            <div className="">
              <span className="block text-sm font-normal leading-5">
                Free Shipping From ₹500.00
              </span>
            </div>
          </div>
          <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
            <div className="mr-3">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="flex-shrink-0 h-4 w-4 text-emerald-600"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
              </svg>
            </div>
            <div className="">
              <span className="block text-sm font-normal leading-5">
                Support 24/7 At Anytime
              </span>
            </div>
          </div>
          <div className=" border-r border-gray-200 py-1 flex items-center justify-center bg-white">
            <div className="mr-3">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="flex-shrink-0 h-4 w-4 text-emerald-600"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
                <line x1="1" y1="10" x2="23" y2="10"></line>
              </svg>
            </div>
            <div className="">
              <span className="block  text-sm font-normal leading-5">
                Secure Payment Totally Safe
              </span>
            </div>
          </div>
          <div className=" py-1 flex items-center justify-center bg-white">
            <div className="mr-3">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="flex-shrink-0 h-4 w-4 text-emerald-600"
                aria-hidden="true"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
              </svg>
            </div>
            <div className="">
              <span className="block text-sm font-normal leading-5">
                Latest Offer Upto 20% Off
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr className="hr-line" />
      <div className="border-t  border-gray-100 w-full">
        <div className="pb-16 lg:pb-0 xl-pb-0 bg-white">
          <div className="mx-auto max-w-screen-2xl px-4 sm:px-10">
                            {/* grid grid-cols-2 md:grid-cols-7 xl:grid-cols-12 gap-9 py-16 justify-between  */}
            <div className="grid grid-cols-2 md:grid-cols-7 xl:grid-cols-9 gap-9 sm:gap-9 lg:gap-11 xl:gap-7 py-10 lg:py-16 justify-between">
              {FooterData.map((data, index) => {
                return (
                  <div
                    key={index}
                    className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3"
                  >
                    <h3 className="text-md lg:leading-7 font-medium mb-4 sm:mb-5 lg:mb-6 pb-0.5">
                      {data.titleHeader}
                    </h3>
                    <ul className="text-sm flex flex-col space-y-3" key={index}>
                      {data.title.map((title, index) => {
                        return (
                          <li key={index} className="flex items-baseline">
                            <Link
                              className="!text-gray-600 inline-block w-full !no-underline hover:!text-emerald-500"
                              to={title.href}
                              target={title.target}
                            >
                              {title.Name}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })}
              <div className="pb-3.5 sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3">
                <Link to="/">
                  <span
                    style={{
                      boxSizing: "border-box",
                      display: "inline-block",
                      overflow: "hidden",
                      width: "initial",
                      height: "initial",
                      background: "none",
                      opacity: "1",
                      border: "0",
                      margin: "0",
                      padding: "0",
                      position: "relative",
                      maxWidth: "100%",
                    }}
                  >
                    <img className=" w-[60px] h-13" src={LogoColor} />
                  </span>
                </Link>

                <p className="leading-7 font-sans text-sm text-gray-600 mt-3">
                  <span> 
                    Anugraha No 29 3rd Floor, 12th Cross Rd, <br /> 
                    Sudheendranagar, Malleshwaram, Bengaluru, Karnataka 560003
                  </span>
                  <br />
                  <span>Tel: +91 8050705897</span>
                  <br />
                  <span>Email: accounts@shreerajgifts.com</span>
                </p>
              </div>
            </div>
            <hr className="hr-line" />
           
          </div>
          <div className="mx-auto max-w-screen-2xl px-3 sm:px-10 flex justify-center py-4">
            <p className="text-sm text-gray-500 leading-6">
              Copyright 2023 @
              <span className="text-emerald-500">ShreerajGifts Pvt Ltd</span>, All rights
              reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
