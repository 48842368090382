import React, { useState,useEffect,useRef } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useParams,useNavigate } from 'react-router-dom';
import axios from "axios"
import{BsPencil} from "react-icons/bs"
import { BiSolidPencil } from 'react-icons/bi';
import plus from "../../assets/img/240_F_481134373_0W4kg2yKeBRHNEklk4F9UXtGHdub3tYk.jpg"

const Pdf = () => {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  let navigate = useNavigate() 
  const [selectedDesign, setSelectedDesign] = useState('classic');
  const [pdfColor, setPdfColor] = useState('#000000'); 
  const [displayPricingInPdf, setDisplayPricingInPdf] = useState(true); 
  const [userDetails,setUserDetails]= useState([])
  const [reload,setReload]= useState(false)
  const [selectedImage,setSelectedImage]= useState("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT1wwaPU6CL-aQEa3w7xm3lQMgyI6ld2pnyeLeprDmWffeIvmUQ2rprHT4PuU0hQ9LzEUg&usqp=CAU")
  const [selectedFile, setSelectedFile] = useState(plus)
  const {id}= useParams()
  const catalogId = id 
  const fileInputRef2 = useRef(null);

  useEffect(()=>{
    const safeRoute =()=>{
        const loginToken = localStorage.getItem("loginToken")
        const userId = localStorage.getItem("userId")
        const secondStage = localStorage.getItem("secondStage")
        if(loginToken=== null || userId === null || secondStage === null){
          navigate("/")
        }
      }
      safeRoute()
},[])


 useEffect(() => {
   const loggedUserDetails = async()=>{
     try{
       const apiResponse = await axios.post(`${baseUrl}/api/v1/profile/getUserProfile`,{
         authId:localStorage.getItem("userId")
       })
       setUserDetails(apiResponse.data[0])
       setSelectedImage(apiResponse.data[0].companyLogo)
     }
     catch(e){
       console.log(e)
     }
   }
   loggedUserDetails()
 }, [reload]);
 

  const handleDesignChange = (event) => {
    setSelectedDesign(event.target.value);
  };

  const handleColorChange = (color) => {
    setPdfColor(color);
  };
  const handleCheckboxChange = (event) => {
    setDisplayPricingInPdf(event.target.checked);
   
  };


  const handleImageClick = (event) => {
    event.preventDefault();
		fileInputRef2.current.click();
	};

  const handleImageUpload = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
      setSelectedFile(file)
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }	
  }



  const handleSubmit = async (event) => {
    event.preventDefault()
    try{
    if(selectedFile){
      const formData = new FormData();
      formData.append("logo", selectedFile);

      const headers={
        authorization:localStorage.getItem("loginToken")
      }
      const imgResponeFromApi = await axios.post(`${baseUrl}/api/v1/profile/addCompanyLogo`, formData,{
        headers,
      })
      setReload(!reload)
    }

        const designMapping = {
          simple: 1,
          modern: 2,
          classic: 3,
        };
        const body ={
          "catalogId":id,
          "colorCode": pdfColor,
          "displayPrices": displayPricingInPdf.toString(),
          "design": designMapping[selectedDesign]
      }
      const generatePdf = await axios.post(`${baseUrl}/api/v1/pdf/generateCatalogPdf`,body)

    if(generatePdf){
      window.location.href=generatePdf.data
    }
  
    }
    catch(e){
      console.log(e)
    }

  };


  return (
    <div className="pdf-generator bg-gray-300 py-10 px-4">
      <div className="max-w-xl mx-auto bg-white rounded-lg shadow-md p-7">
        <h2 className="text-2xl mb-6 text-white border border-1 bg-emerald-500 rounded text-center p-2">PDF Generator</h2>
        <div className=" space-x-4">
          <div className="w-full mb-4 mb-0">
            <label className="block">
              Upload Brand Image
              <div className='inline py-2 m-3 flex justify-center max-w-80'   >
                <div  onClick={handleImageClick}>
                  <span  style={{ position: 'relative' }}>
                <img
                  src={selectedImage}
                  alt='Selected'
               
                  style={{ cursor: 'pointer' }}
                  className='w-[300px] h-[200px] rounded border-1 border-black'
                />
               
                <BiSolidPencil size={24} className="text-emerald-500" style={{ position: 'absolute', top: '10', right: '10', cursor: 'pointer' }} />
                </span>
                </div>
              
                <input
                  type='file'
                  ref={fileInputRef2}
                  accept='image/*'
                  onChange={handleImageUpload}
                  style={{ display: 'none' }}
                  key={selectedImage} 
                />
					</div>	
              
            </label>
          </div>
          <div className="w-full">
            <form onSubmit={handleSubmit}>
             
              <label className="block mb-4">
        <input
          type="checkbox"
          className="mr-2"
          checked={displayPricingInPdf}
          onChange={handleCheckboxChange}
        />
        Display product pricing in PDF
      </label>
              <label className="block mb-4">
                Select Design
                <select
                  value={selectedDesign}
                  onChange={handleDesignChange}
                  className="mt-1 p-2 border rounded w-full outline-none focus:ring-0"
                >
                  <option value="">Select</option>
                  <option value="classic">Classic</option>
                  <option value="modern">Modern</option>
                  <option value="simple">Simple</option>
                </select> 
              </label>
              <label className="block mb-4">
                Select PDF Color
                <span className= "text-xl font-bold" style={{color:pdfColor}}
                > {pdfColor}</span>
                <div className="my-5">
                  <HexColorPicker color={pdfColor} onChange={handleColorChange} />
                </div>
              </label>
            
              <button
                type="submit"
                className="bg-emerald-500 text-white px-4 py-2 rounded hover:bg-emerald-600"
              >
                Generate PDF
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Pdf;

