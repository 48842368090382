import React from "react";
import NotFound from "../../assets/img/no-result.svg";
import NoSavedCatalogs from"../../assets/img/nosavedcatalogs.jpg"
import NoProducts from "../../assets/img/noproducts.png"
import noorder from "../../assets/img/no order.jpg"

import { useNavigate } from "react-router-dom";

 export const NoSavedCatalogsFound = () => {
  return (
    <div className="text-center align-middle mx-auto p-5 my-5">
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="no-result"
          src={NoSavedCatalogs}
          decoding="async"
          data-nimg="intrinsic"
          className="my-4 w-1/3 h-1/3  "
          
        />
      </span>

      <h2 className="text-lg md:text-xl lg:text-2xl xl:text-2xl text-center mt-2 font-medium text-gray-600">

      No Catalogs Created, Craft Your Catalog Today!
      </h2>
    </div>
  );
};

export const NoProductsFound = () => {
    const navigate = useNavigate()
    return (
      <div className="text-center align-middle mx-auto p-5 my-5">
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="no-result"
            src={NoProducts}
            decoding="async"
            data-nimg="intrinsic"
            className="my-4 w-1/3 h-1/3  "
            
          />
        </span>
  
        {/* <h2 className="text-lg md:text-xl lg:text-2xl xl:text-2xl text-center mt-2 font-medium text-gray-600">
          No Products Found In catalog 
        </h2> */}
        <button 
        onClick={()=>{
            navigate("/")
        }}
        class="bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg px-4 py-2 shadow-md transition duration-300 ease-in-out mt-2">
    Add Products
</button>

      </div>
    );
  };
  export const Dummy = () => {
    const navigate = useNavigate()
    return (
      <div className="text-center align-middle mx-auto p-5 my-5">
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="no-result"
            src={NoProducts}
            decoding="async"
            data-nimg="intrinsic"
            className="my-4 w-1/3 h-1/3  "
            
          />
        </span>
  
        
        <button 
        onClick={()=>{
            navigate("/")
        }}
        class="bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg px-4 py-2 shadow-md transition duration-300 ease-in-out mt-2">
    Add Products
</button>

      </div>
    );
  };

  export const Noorder = () => {
    const navigate = useNavigate()
    return (
      <div className="text-center align-middle mx-auto p-5 my-5">
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            alt="no-result"
            src={noorder}
            decoding="async"
            data-nimg="intrinsic"
            className="my-4 w-1/3 h-1/3  "
            
          />
        </span>
  
        <h2 className="text-lg md:text-xl lg:text-2xl xl:text-2xl text-center mt-2 font-medium text-gray-600">
          No Orders Placed
        </h2>
        <button 
        onClick={()=>{
            navigate("/")
        }}
        class="bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg px-4 py-2 shadow-md transition duration-300 ease-in-out mt-2">
    Place Order
</button>

      </div>
    );
  };
  

