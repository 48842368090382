import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import NotFound from "../../assets/img/no-result.svg";
const OrderReceived = () => {
    const { id } = useParams(); 
    const navigate= useNavigate()
  return (
    <div className="text-center align-middle mx-auto p-5 my-5">
      <span
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt="no-result"
          src="https://img.freepik.com/free-vector/green-double-circle-check-mark_78370-1749.jpg?w=740&t=st=1692773434~exp=1692774034~hmac=566af0f265417a48fc6553313d21fd43ca978e092db60d9d078635976c390253"
          decoding="async"
          data-nimg="intrinsic"
          className="my-4 w-1/6 h-1/6  "
        />
      </span>

      <h2 className="text-lg md:text-xl lg:text-2xl xl:text-2xl text-center mt-2 font-medium text-gray-600">
            Thanks For Your Order    
      </h2>
      {/* <h2 className="text-lg md:text-xl lg:text-2xl xl:text-2xl text-center mt-2 font-medium text-gray-600"> Your Order id :<span className="text-emerald-500"> {id}</span> </h2> */}
      <p className=""> Stay Tuned For Shipping Notification Within 7 Days </p>
   
      <button
      onClick={()=>{
        navigate("/user/my-orders")
      }
      }className="mt-4 px-4 py-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-md shadow-md focus:outline-none focus:ring focus:ring-emerald-300 w-36"
          >
            View My Order
          </button>
        <br/>
          <button
      onClick={()=>{
        navigate("/")
      }
      }className="mt-4 px-3 py-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-md shadow-md focus:outline-none focus:ring focus:ring-emerald-300 w-36"
          >
            Go Back To Home
          </button>
          {/* <div className="flex flex-col gap-4">
 
</div> */}
   </div> 
  );
};

export default OrderReceived;
