import React,{useState} from "react";
import "swiper/css";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { CategoriesData } from "../../fakeData/CategoriesData";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { searchAction } from "../../store/reducers/searchSlice";

function SliceCategory({ data, setSelectedCategoryId }) {
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  } const [clickedCategoryId, setClickedCategoryId] = useState(null);

  const handleClicks = (categoryId) => {
    setClickedCategoryId(categoryId);
  };
  

  const handleClick = (id) => {
    setSelectedCategoryId(id);
  };
  return (
    <div className="relative">
      <Swiper
        slidesPerView={1}
        spaceBetween={8}
        navigation={true}
        slidesPerGroup={1}
        loop={false}
        breakpoints={{
          375: {
            slidesPerView: 2,
            spaceBetween: 8,
            slidesPerGroup: 1,
            loop: false,
          },
          425: {
            slidesPerView: 3,
            spaceBetween: 8,
            slidesPerGroup: 1,
            loop: false,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 8,
            slidesPerGroup: 1,
            loop: false,
          },
          768: {
            slidesPerView: 5,
            spaceBetween: 8,
            slidesPerGroup: 1,
            loop: false,
          },
          1024: {
            slidesPerView: 8,
            spaceBetween: 8,
            slidesPerGroup: 1,
            loop: false,
          },
          1440: {
            slidesPerView: 10,
            spaceBetween: 8,
            slidesPerGroup: 1,
            loop: false,
          },
        }}
        modules={[Navigation]}
        className="mySwiper category-slider my-10"
      >
        {data.map((category, index) => {
          return (
            <SwiperSlide key={index} className="group" style={{ width: "30%" }}>
              <div
                onClick={() => {
                  handleClicks(category._id)
                  handleClick(category._id)}}
                className={`text-center cursor-pointer p-3 bg-white rounded-lg ${
                  clickedCategoryId === category._id
                    ? 'text-emerald-500'
                    : "text-gray-600"  }`}
              >
                <img
                  src={category.image}
                  className="bg-white  mx-auto w-[5rem] h-[5rem] rounded-full shadow-md"
                />
              </div>
              <h3
              className={`text-xs text-center  mt-2 cursor-pointer ${
              clickedCategoryId === category._id
                ? 'text-emerald-500'
                : 'text-gray-600'
            }`}>
                {category.name}
              </h3>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
}

export default SliceCategory;
