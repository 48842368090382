
import React,{useState,useEffect} from "react";
import { ShimmerPostDetails } from "react-shimmer-effects";
import axios from "axios"
import { useNavigate,Link } from "react-router-dom";
import { Noorder } from "../catalogsFallbacks/catalogFallbacks";

//  <ShimmerPostDetails card cta variant="SIMPLE" />
// <ShimmerPostDetails card cta variant="EDITOR" /> 

const Table = ({title,data,children}) => {
  const navigate = useNavigate()
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;

  }
  const [orderDetails,setOrderDetails] = useState([])
  const [showShimmer,setShowShimmer]= useState(true)

  useEffect(()=>{
    const fetchOrderDetails = async ()=>{

      try{
        const headers={
          authorization:localStorage.getItem("loginToken")
        }
          const orderDetailsApi = await axios.get(`${baseUrl}/api/v1/order/listMyOrders`,{headers,})
          const finalData=[]
          for(let i = 0 ; i<orderDetailsApi.data.length; i++){
            
            const orderFullDetails = await axios.post(`${baseUrl}/api/v1/order/fetchOrderDetails`,{
                "orderId": orderDetailsApi.data[i].orderId,
                "_id": localStorage.getItem("userId")
            },{
              headers,
            })
            const filteredLength = orderFullDetails.data.products.filter((each)=>{
              if(each.quantity>0){
                return each
              }
            }) 
            const modifiedOrderDetails = {...orderDetailsApi.data[i],items:filteredLength.length}
            finalData.push(modifiedOrderDetails)
          }
          // setOrderDetails(orderDetailsApi.data)
          setOrderDetails(finalData.reverse())
          setShowShimmer(false)
      }
      catch(e){
        console.log(e)
      }

    }
    fetchOrderDetails()
   
  },[])

  if(showShimmer){
    return(
      <>
      <ShimmerPostDetails card cta variant="SIMPLE" />
 {/* <ShimmerPostDetails card cta variant="EDITOR" />  */}
 </>
    )

  }
  else{
    return (
      <>
      {
        orderDetails.length > 0 ?   
        (
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="max-w-screen-2xl mx-auto ">
        <div className="rounded-md ">
          <div className="flex flex-col">
           {title&& <h3 className="text-lg font-medium mb-5">{title}</h3>}
          
            <div className="align-middle inline-block   rounded-md min-w-full pb-2 sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b last:border-b-0 border-gray-100 rounded-md">
                <table className="table-auto min-w-full border border-gray-100 divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr className="bg-gray-100">
                      <th
                        scope="col"
                        className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                      >
                       ORDER ID
                      </th>
                      <th
                        scope="col"
                        className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                      >
                        STATUS
                      </th>
                      <th
                        scope="col"
                        className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                      >
                        ITEMS
                      </th>
                      <th
                        scope="col"
                        className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                      >
                        TOTAL
                      </th>
                      <th
                        scope="col"
                        className="text-left text-xs font-serif font-semibold px-6 py-2 text-gray-700 uppercase tracking-wider"
                      >
                       {/* DETAILS */}
                      </th>
                      
  
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {
                      orderDetails.length > 0 ?  
                        orderDetails.map((eachOrder)=>(
                        <tr>
                        <td className="px-10 py-3 leading-6 whitespace-nowrap">
                        <span className="uppercase text-sm font-medium">#{eachOrder.orderId}</span>
                        </td>
                        <td className="px-5 py-3 leading-6 whitespace-nowrap">
                        <span className="uppercase text-sm font-medium">{eachOrder.status}</span>
                        </td>
                        <td className="px-10 py-3 leading-6 whitespace-nowrap">
                        <span className="uppercase text-sm font-medium">{eachOrder.items}</span>
                        </td>
                        <td className="px-7 py-3 leading-6 whitespace-nowrap">
                        <span className="uppercase text-sm font-medium">₹{eachOrder.total.toLocaleString('en-IN')}/-</span>
                        </td>
                       
                      <td className="px-5 py-3 leading-6 whitespace-nowrap">
                        <Link to= {`orderDetails/${eachOrder.orderId}`} >
                        <button  
                        // onClick={()=>{
                        //  navigate("/")
                        //   navigate(`orderDetails/${eachOrder.orderId}`)
                        // }}
                         className=" border border-emerald-500  px-4 py-2 rounded-xl uppercase text-sm font-medium text-emerald-500 hover:bg-emerald-600 hover:text-white focus:outline-none">
                          Order Details
                        </button>
                        
                        </Link>
                       
                      </td>
                      </tr>)
                        )   
                    :""
                    }
                    {/* <tr>
                      <td className="px-5 py-3 leading-6 whitespace-nowrap">
                      <span className="uppercase text-sm font-medium">1234#</span>
                      </td>
                      <td className="px-5 py-3 leading-6 whitespace-nowrap">
                      <span className="uppercase text-sm font-medium">status</span>
                      </td>
                      <td className="px-5 py-3 leading-6 whitespace-nowrap">
                      <span className="uppercase text-sm font-medium">2</span>
                      </td>
                      <td className="px-5 py-3 leading-6 whitespace-nowrap">
                      <span className="uppercase text-sm font-medium">9126</span>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          </div>
          </div>
        </div>) 
          : (<Noorder/>)
           }
           </>
          
       
    );

  }


};

export default Table;
