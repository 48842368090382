
import React, { useEffect,useContext,useState } from "react";
import OrderSummary from "../orderSummary/OrderSummary";

import {  useNavigate } from "react-router-dom";

import randomId from "random-id";
import CartContext from "../../context/cartContext";
import axios from "axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function Checkout({setReload} ) {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    const safeRoute =()=>{
      const loginToken = localStorage.getItem("loginToken")
      const userId = localStorage.getItem("userId")
      const secondStage = localStorage.getItem("secondStage")
      if(loginToken=== null || userId === null || secondStage === null){
        navigate("/")
      }
    }
    safeRoute()

  }, []);

 
  
 const navigate = useNavigate()
 const {
  cartList,
} = useContext(CartContext);
const filteredCartList = cartList.filter((each)=>each.quantity !== 0)
 

let totalCost = filteredCartList.reduce((total, item) => {
  return total + ((item.price+(item.gst/100*item.price))*item.quantity)
}, 0);
totalCost= Math.round(totalCost)

  const [sameAddress, setSameAddress] = useState(true);
  const [savedAddress,setSavedAddress]= useState([])
  const [disableRadioBox,setDisableRadioBox]= useState(false)
  const [wantToSaveNewAddress,setWantToSaveAddress]= useState(true)
  const [selectedAddress,setSelectedAddress]= useState({
    shippingAddress:{
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
    shippingAddress:{
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      country: "",
      pincode: "",
    },
    _id:"",
  })

  useEffect(()=>{
    const headers = {
        authorization: localStorage.getItem("loginToken"),
      };
    
    const getUserAddresses= async()=>{
        try{
            const userAddressResponse = await axios.get(`${baseUrl}/api/v1/profile/getSavedAddresses`,{
                headers,
               })
               setSavedAddress(userAddressResponse.data)
        }
        catch(e){
            console.log(e)
        }  
    }
    getUserAddresses()
  },[])

  const handleCheckboxChange = () => {
    setSameAddress(!sameAddress);
  };
  const handleSaveAddress = () => {
    setWantToSaveAddress(!wantToSaveNewAddress);
  };

const handleNewOrder= async()=>{
  const headers = {
    authorization:localStorage.getItem("loginToken")
  }
  const currentDate = new Date();

// Extract year, month, and day
const year = currentDate.getFullYear();
const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
const day = String(currentDate.getDate()).padStart(2, '0');

// Format the date as 'YYYY-MM-DD' (e.g., '2023-08-28')
const TODAY = `${year}-${month}-${day}`;

const isShippingPhoneValid = selectedAddress.shippingAddress.phone
const {name,email,phone,address,city,pincode,state,country}=selectedAddress.shippingAddress
const cleanedName = name.replace(/\s+/g, '');
const cleanedAddress = address.replace(/\s+/g, '');
const cleanedCity = city.replace(/\s+/g, '');
const cleanedPincode = pincode.replace(/\s+/g, '');
const cleanedState = state.replace(/\s+/g, '');
const cleanedCountry = country.replace(/\s+/g, '');

if(cleanedName===""||cleanedAddress===""|| cleanedCity===""||cleanedPincode===""||cleanedState===""||cleanedCountry===""){
  toast.warn('Please Provide Valid Input', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    }); 

}
else if(isShippingPhoneValid.length !== 10){
toast.warn('Please Enter Valid Phone Number', {
position: "top-right",
autoClose: 5000,
hideProgressBar: false,
closeOnClick: true,
pauseOnHover: true,
draggable: true,
progress: undefined,
theme: "colored",
});       
}
else if (sameAddress === false && selectedAddress.billingAddress.phone.length!== 10 ){
  toast.warn('Please Enter Valid  Billing Phone Number', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    }); 
}
else if (sameAddress === false && selectedAddress.billingAddress.pincode.length!== 6 ){
  toast.warn('Please Enter Valid  Billing Pincode ', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
    }); 
}

else{
  try{
    if(selectedAddress._id === ""){
      //new address so save it 
      if(sameAddress){
        //new address but billing and shipping same
        const {name,email,phone,address,city,pincode,state,country}=selectedAddress.shippingAddress
        
      
        const bodyData={
          "shippingAddress": {
            name,
            email,
            phone,
            address,
            city,
            pincode,
            state,
            country,
          },
          "billingAddress": {
            name,
            email,
            phone,
            address,
            city,
            pincode,
            state,
            country,
          }
        }
        if(parseInt(pincode)<0 || pincode.length!==6 ){
          toast.warn('Please Enter Valid pincode', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }); 
        }


        else{
          if(wantToSaveNewAddress){
            const saveNewAddressResponse = await axios.post(`${baseUrl}/api/v1/profile/addSavedAddresses`, bodyData,{
              headers,
            })
          }
       
        //place order
        if(filteredCartList.length>0){   
          const orderBody={
            "shippingName": name,
            "shippingEmail": email,
            "shippingPhone": phone,
            "shippingAddress": address,
            "shippingCity": city,
            "shippingState": state,
            "shippingCountry": country,
            "shippingPincode": pincode,
            "billingName":name ,
            "billingEmail":email,
            "billingPhone": phone,
            "billingAddress": address,
            "billingCity": city,
            "billingState": state,
            "billingCountry":country,
            "billingPincode": pincode,
            "discount": "10",
            "total": totalCost,
            "status":"RECEIVED",
            "orderDate": TODAY,
            "deliveryDate": null,
            "returnDate": null,
            "replacementDate": null,
            "cancelDate": null,
            "_id": localStorage.getItem("userId")
          }
          
          const placeOrder = await axios.post(`${baseUrl}/api/v1/order/createOrder`, orderBody,{
            headers,
          })
          toast.success('Order Placed Succesfully', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: {
              background: 'rgb(5 150 105)', // Your custom color
              color: '#ffffff', // Text color
              fontWeight: 'bold', // Font weight
            },
            });
  
          setReload((prev)=>!prev)
        
          navigate(`/order/${placeOrder.data.orderId}`)
          }
          else{
         
            toast.warn('Please Add Items To Your Cart To Place Order', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              });
          }

        }
       
      }

      else if (!sameAddress){
        //new address but billing billing and shipping is different
        const {name,email,phone,address,city,pincode,state,country}=selectedAddress.shippingAddress
       const cleanedName = selectedAddress.billingAddress.name.replace(/\s+/g, '');
       const cleanedAddress = selectedAddress.billingAddress.address.replace(/\s+/g, '');
       const cleanedCity = selectedAddress.billingAddress.city.replace(/\s+/g, '');
       const cleanedState = selectedAddress.billingAddress.state.replace(/\s+/g, '');
       const cleanedCountry = selectedAddress.billingAddress.country.replace(/\s+/g, '');
       if(cleanedName===""|| cleanedAddress===""|| cleanedCity===""|| cleanedState===""||cleanedCountry===""){
        toast.warn('Please Enter Valid Input', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });  
       }
       else if(parseInt(pincode)<0 || parseInt(selectedAddress.billingAddress.pincode)<0 || pincode.length !== 6){
          toast.warn('Please Enter Valid pincode', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }); 
        }
        else if(selectedAddress.billingAddress.phone.length !== 10){
          toast.warn('Please Enter Valid Phone Number', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            }); 
        }
        else{
          const bodyData={
            "shippingAddress": {
              name,
              email,
              phone,
              address,
              city,
              pincode,
              state,
              country,
            },
            "billingAddress": {
              name:selectedAddress.billingAddress.name,
              email:selectedAddress.billingAddress.email,
              phone:selectedAddress.billingAddress.phone,
              address:selectedAddress.billingAddress.address,
              city:selectedAddress.billingAddress.city,
              pincode:selectedAddress.billingAddress.pincode,
              state:selectedAddress.billingAddress.state,
              country:selectedAddress.billingAddress.country,
            }
          }
          if(wantToSaveNewAddress){
            const saveNewAddressResponseButBillingDifferent = await axios.post(`${baseUrl}/api/v1/profile/addSavedAddresses`, bodyData,{
              headers,
            })
          }
       
          //place order 
          if(filteredCartList.length>0){   
            const orderBody={
              "shippingName": name,
              "shippingEmail": email,
              "shippingPhone": phone,
              "shippingAddress": address,
              "shippingCity": city,
              "shippingState": state,
              "shippingCountry": country,
              "shippingPincode": pincode,
              "billingName":selectedAddress.billingAddress.name ,
              "billingEmail":selectedAddress.billingAddress.email,
              "billingPhone": selectedAddress.billingAddress.phone,
              "billingAddress": selectedAddress.billingAddress.address,
              "billingCity": selectedAddress.billingAddress.city,
              "billingState": selectedAddress.billingAddress.state,
              "billingCountry":selectedAddress.billingAddress.country,
              "billingPincode": selectedAddress.billingAddress.pincode,
              "discount": "10",
              "total": totalCost,
              "status": "RECEIVED",
              "orderDate": TODAY,
              "deliveryDate": null,
              "returnDate": null,
              "replacementDate": null,
              "cancelDate": null,
              "_id": localStorage.getItem("userId")
            }
            
            const placeOrder = await axios.post(`${baseUrl}/api/v1/order/createOrder`, orderBody,{
              headers,
            })
            // alert("order placed succesfully @billing and shipping different")
            toast.success('Order Placed Succesfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              style: {
                background: 'rgb(5 150 105)', // Your custom color
                color: '#ffffff', // Text color
                fontWeight: 'bold', // Font weight
              },
              });
  
            setReload((prev)=>!prev)
            navigate(`/order/${placeOrder.data.orderId}`)
            }
            else{
              // alert("please add items to your cart to place order")
              toast.warn('Please Add Items To Your Cart To Place Order', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                
                });
            }
  

        }

     
      }
    }
    else if(selectedAddress._id!== ""){
      // alert("entered saved address block")
      //user selected some address and editing it so update address
      const {name,email,phone,address,city,pincode,state,country}=selectedAddress.shippingAddress

      const cleanedName = selectedAddress.billingAddress.name.replace(/\s+/g, '');
      const cleanedAddress = selectedAddress.billingAddress.address.replace(/\s+/g, '');
      const cleanedCity = selectedAddress.billingAddress.city.replace(/\s+/g, '');
      const cleanedState = selectedAddress.billingAddress.state.replace(/\s+/g, '');
      const cleanedCountry = selectedAddress.billingAddress.country.replace(/\s+/g, '');

      if(cleanedName===""|| cleanedAddress===""|| cleanedCity===""|| cleanedState===""||cleanedCountry===""){
        toast.warn('Please Enter Valid Input', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });  
       }
       else{
        const bodyData=
        {
          "savedAddressId": selectedAddress._id,
          "shippingAddress": {
              name,
              email,
              phone,
              address,
              city,
              pincode,
              state,
             country,
          },
          "billingAddress": {
              "name": selectedAddress.billingAddress.name,
              "email": selectedAddress.billingAddress.email,
              "phone": selectedAddress.billingAddress.phone,
              "address": selectedAddress.billingAddress.address,
              "city": selectedAddress.billingAddress.city,
              "pincode": selectedAddress.billingAddress.pincode,
              "state": selectedAddress.billingAddress.state,
              "country": selectedAddress.billingAddress.country,
          }
      }
      const updateSavedAddressResponse = await axios.put(`${baseUrl}/api/v1/profile/updateSavedAddresses`, bodyData,{
        headers,
      })

      //place order
      if(filteredCartList.length>0){   
      const orderBody={
        "shippingName": name,
        "shippingEmail": email,
        "shippingPhone": phone,
        "shippingAddress": address,
        "shippingCity": city,
        "shippingState": state,
        "shippingCountry": country,
        "shippingPincode": pincode,
        "billingName":selectedAddress.billingAddress.name ,
        "billingEmail":selectedAddress.billingAddress.email,
        "billingPhone": selectedAddress.billingAddress.phone,
        "billingAddress": selectedAddress.billingAddress.address,
        "billingCity": selectedAddress.billingAddress.city,
        "billingState": selectedAddress.billingAddress.state,
        "billingCountry":selectedAddress.billingAddress.country,
        "billingPincode": selectedAddress.billingAddress.pincode,
        "discount": "10",
        "total": totalCost,
        "status": "RECEIVED",
        "orderDate": TODAY,
        "deliveryDate": null,
        "returnDate": null,
        "replacementDate": null,
        "cancelDate": null,
        "_id": localStorage.getItem("userId")
      }
      
      const placeOrder = await axios.post(`${baseUrl}/api/v1/order/createOrder`, orderBody,{
        headers,
      })
      // alert("order placed succesfully @billing and shipping of saved address")
      toast.success('Order Placed Succesfully', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          background: 'rgb(5 150 105)', // Your custom color
          color: '#ffffff', // Text color
          fontWeight: 'bold', // Font weight
        },
        });
      setReload((prev)=>!prev)
      
      navigate(`/order/${placeOrder.data.orderId}`)

      }
      else{
        // alert("please add items to your cart to place order")
        toast.warn('Please Add Items To Your Cart To Place Order', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          
          });
      }

       }
      

    
    }

  }
  catch(e){
    console.log(e)
  }

}


}

const handleWheel = (event) => {
  event.target.blur(); // Blur the input element
};



  return (
  
    <div className="bg-gray-50">
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="py-10 lg:py-12 px-0 2xl:max-w-screen-2xl w-full xl:max-w-screen-xl flex flex-col md:flex-row lg:flex-row">
          <div className="md:w-full lg:w-3/5 flex h-full flex-col order-2 sm:order-1 lg:order-1">
            <div className="mt-5 md:mt-0 md:col-span-2 ">
              { savedAddress.length>0?<h5>Select One Address</h5>:""}
              <div className=" p-3 max-h-72  rounded-xl mt-3 overflow-auto">
            {
              savedAddress.length > 0 ?
                savedAddress.map((eachAddress,index)=>{
                  const{  address,city,country,email,name,phone,pincode,state,} = eachAddress.shippingAddress
                 
                  return(
                    <div className="container mx-auto    border border-2 py-2 px-2  mt-2 rounded-xl" key={index} >
                          <label  htmlFor={address+index} className="cursor-pointer" >
                          <div className="bg-white shadow-md rounded-md p-4 flex  ">
                            <input type="radio" className="mr-3 form-radio text-emerald-500"  id={address+index} name="addressGroup" onChange={(e)=>{
                              setSelectedAddress(eachAddress)
                              setSameAddress(false)
                              setDisableRadioBox(true)
                            }}/>
                            <p className="text-sm text-center overflow-auto max-h-32"> {name} |{phone} | {email} | {address} | {city} | {state} | {country} | {pincode}</p>
                          </div>
                          </label>
                      </div>
                  )
                })    
              :""
            }
            </div>
             
            <form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleNewOrder();
                }}
              >
                <div className="mt-5">
                  <h2 className="font-semibold  text-base text-gray-700 pb-3">
                    Shipping Address
                  </h2>
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="Name"
                        className="block text-gray-500 font-medium text-sm leading-none mb-2"
                      >
                        Name
                      </label>
                      <div className="relative">
                        <input
                          className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12 "
                          id="Name"
                          name="Name"
                          type="text"
                          placeholder="Enter Your Name"
                          required

      
                          value={selectedAddress?.shippingAddress?.name}
                          onChange={(e) => {
                            setSelectedAddress((prev) => {
                              return {
                                ...prev,
                                shippingAddress:{
                                  ...prev.shippingAddress,
                                   name: e.target.value,
                                } 
                              };
                            });
                          }}
                        />
                      </div>

                      <label
                        htmlFor="email"
                        className="block text-gray-500 font-medium text-sm leading-none my-2 mt-6"
                      >
                        Email Address
                      </label>
                      <div className="relative">
                        <input
                          className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                          id="email"
                          name="email"
                          type="email"
                          placeholder="info@gmail.com"
                          required
                          value={selectedAddress?.shippingAddress?.email}
                          onChange={(e) => {
                            setSelectedAddress((prev) => {
                              return {
                                ...prev,
                                shippingAddress:{
                                  ...prev.shippingAddress,
                                   email: e.target.value,
                                }
                              };
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-6 sm:col-span-3">
                      

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="phoneNumber"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Phone Number
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12 no-scroll-input"
                            id="phoneNumber"
                            name="phoneNumber"
                            type="number"
                            onWheel={handleWheel}
                            min="0"
                            placeholder="+91-1234567891"
                            required
                            value={selectedAddress?.shippingAddress?.phone}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                  shippingAddress:{
                                    ...prev.shippingAddress,
                                     phone: e.target.value,
                                  }
                                 
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12">
                    <div className="grid grid-cols-6 gap-6 mb-8">
                      <div className="col-span-6 ">
                        <label
                          htmlFor="Address"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Address
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="Address"
                            name="Address"
                            placeholder="Enter your Address"
                            required
                            value={selectedAddress?.shippingAddress?.address}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                  shippingAddress:{
                                    ...prev.shippingAddress,
                                     address: e.target.value,
                                  }
                                  
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="city"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          City
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="city"
                            name="city"
                            placeholder="Enter Your City"
                            required
                            value={selectedAddress?.shippingAddress?.city}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                  shippingAddress:{
                                    ...prev.shippingAddress,
                                     city: e.target.value,
                                  }
                                
                                };
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="zipPostal"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          ZIP / Postal
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12 no-scroll-input"
                            id="zipPostal"
                            name="zipPostal"
                            placeholder="34000"
                            onWheel={handleWheel}
                            type="number"
                            required
                            min="0"
                            value={selectedAddress?.shippingAddress?.pincode}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                  shippingAddress:{
                                    ...prev.shippingAddress,
                                     pincode: e.target.value,
                                  }
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3 ">
                        <label
                          htmlFor="state"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          State
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="state"
                            name="state"
                            placeholder="Enter Your State"
                            required
                            value={selectedAddress?.shippingAddress?.state}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                 
                                  shippingAddress:{
                                    ...prev.shippingAddress,
                                     state: e.target.value,
                                  }
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="country"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Country
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="country"
                            name="country"
                            placeholder="Enter Your Country"
                            type="text"
                            required
                            value={selectedAddress?.shippingAddress?.country}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                  country: e.target.value,
                                  shippingAddress:{
                                    ...prev.shippingAddress,
                                     country: e.target.value,
                                  }
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <h6>Billing Address</h6>
                    <div className="flex justify-start items-center w-full mt-3">
                      <input
                        onChange={handleCheckboxChange}
                        checked={sameAddress}
                        disabled={disableRadioBox}
                        type="checkbox"
                        className="form-checkbox border-emerald-600 text-emerald-600 bg-white checked:bg-emerald-600 checked:border-transparent focus:ring-emerald-300 m-4"
                      />
                      <p>Billing Address same as shipping Address</p>
                    </div>
                  </div>
                  <div
                      className={
                        !sameAddress ? " hidden" : "col-span-6 sm:col-span-3"
                      }
                    >
                  <div className="flex justify-start items-center w-full mt-3">
                      <input
                        onChange={handleSaveAddress}
                        checked={wantToSaveNewAddress}
                        disabled={disableRadioBox}
                        type="checkbox"
                        className="form-checkbox border-emerald-600 text-emerald-600 bg-white checked:bg-emerald-600 checked:border-transparent focus:ring-emerald-300 m-4"
                      />
                      <p>Save This Address?</p>
                    </div>
            
                 </div>
                  <div className="grid justify-center grid-cols-6 gap-4 lg:gap-6 mt-9">
                    <div
                      className={
                        !sameAddress ? " hidden" : "col-span-6 sm:col-span-3"
                      }
                    >
                      <button
                        type="submit"
                        className="bg-emerald-500 hover:bg-emerald-600 border border-emerald-500 transition-all rounded py-3 text-center text-sm  font-medium text-white flex justify-center w-full"
                      >
                        Save & Proceed{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </form>
              {!sameAddress && (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleNewOrder();
                  }}
                >
                  <div>
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-3">
                        <label
                          htmlFor="Name"
                          className="block text-gray-500 font-medium text-sm leading-none mb-2"
                        >
                          Name
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="Name"
                            name="Name"
                            placeholder="Enter Your Name"
                            required
                            value={selectedAddress?.billingAddress?.name}
                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                  billingAddress:{
                                    ...prev.billingAddress,
                                     name: e.target.value,
                                  }
                                  
                                };
                              });
                            }}
                          />
                        </div>

                        <label
                          htmlFor="email1"
                          className="block text-gray-500 font-medium text-sm leading-none my-2 mt-6"
                        >
                          Email Address
                        </label>
                        <div className="relative">
                          <input
                            className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                            id="email1"
                            name="email1"
                            type="email"
                            placeholder="info@gmail.com"
                          
                            required
                            value={selectedAddress?.billingAddress?.email}

                            onChange={(e) => {
                              setSelectedAddress((prev) => {
                                return {
                                  ...prev,
                                 
                                  billingAddress:{
                                    ...prev.billingAddress,
                                     email: e.target.value,
                                  }
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-span-6 sm:col-span-3">
                        

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="phoneNumber"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2"
                          >
                            Phone Number
                          </label>
                          <div className="relative">
                            <input
                              className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12 no-scroll-input"
                              id="phoneNumber"
                              name="phoneNumber"
                              placeholder="+91-1234567891"
                              onWheel={handleWheel}
                              type="number"
                              min="0"
                              required
                              value={selectedAddress?.billingAddress?.phone}

                              onChange={(e) => {
                                setSelectedAddress((prev) => {
                                  return {
                                    ...prev,
                                    billingAddress:{
                                      ...prev.billingAddress,
                                       phone: e.target.value,
                                    } 
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-12">
                      <div className="grid grid-cols-6 gap-6 mb-8">
                        <div className="col-span-6 ">
                          <label
                            htmlFor="Address"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2"
                          >
                            Address
                          </label>
                          <div className="relative">
                            <input
                              className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="Address"
                              name="Address"
                              placeholder="Enter Your Address"
                              required
                              value={selectedAddress?.billingAddress?.address}

                              onChange={(e) => {
                                setSelectedAddress((prev) => {
                                  return {
                                    ...prev,
                                    billingAddress:{
                                      ...prev.billingAddress,
                                       address: e.target.value,
                                    }
                                    
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="city"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2"
                          >
                            City
                          </label>
                          <div className="relative">
                            <input
                              className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="city"
                              name="city"
                              placeholder="Enter Your City"
                              required
                              value={selectedAddress?.billingAddress?.city}
                              onChange={(e) => {
                                setSelectedAddress((prev) => {
                                  return {
                                    ...prev,
                                  
                                    billingAddress:{
                                      ...prev.billingAddress,
                                       city: e.target.value,
                                    }
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="zipPostal"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2"
                          >
                            ZIP / Postal
                          </label>
                          <div className="relative">
                            <input
                              className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12 no-scroll-input"
                              id="zipPostal"
                              name="zipPostal"
                              placeholder="34000"
                              min="0"
                              onWheel={handleWheel}
                              type="number"
                              required
                              value={selectedAddress?.billingAddress?.pincode}
                              onChange={(e) => {
                                setSelectedAddress((prev) => {
                                  return {
                                    ...prev,
                                    
                                    billingAddress:{
                                      ...prev.billingAddress,
                                       pincode: e.target.value,
                                    }
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3 ">
                          <label
                            htmlFor="state"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2"
                          >
                            State
                          </label>
                          <div className="relative">
                            <input
                              className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="state"
                              name="state"
                              placeholder="Enter Your State"
                              required
                              value={selectedAddress?.billingAddress?.state}
                              onChange={(e) => {
                                setSelectedAddress((prev) => {
                                  return {
                                    ...prev,
                                   
                                    billingAddress:{
                                      ...prev.billingAddress,
                                       state: e.target.value,
                                    }
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="country"
                            className="block text-gray-500 font-medium text-sm leading-none mb-2"
                          >
                            Country
                          </label>
                          <div className="relative">
                            <input
                              className="py-2 px-4 md:px-5 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg:white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="country"
                              name="country"
                              placeholder="Enter Your Country"
                              type="text"
                              required
                              value={selectedAddress?.billingAddress?.country}
                              onChange={(e) => {
                                setSelectedAddress((prev) => {
                                  return {
                                    ...prev,
                                   
                                    billingAddress:{
                                      ...prev.billingAddress,
                                       country: e.target.value,
                                    }
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      disableRadioBox === false &&  <div className="flex justify-start items-center w-full mt-3">
                      <input
                        onChange={handleSaveAddress}
                        checked={wantToSaveNewAddress}
                        disabled={disableRadioBox}
                        type="checkbox"
                        className="form-checkbox border-emerald-600 text-emerald-600 bg-white checked:bg-emerald-600 checked:border-transparent focus:ring-emerald-300 m-4"
                      />
                      <p>Save This Address?</p>
                    </div>
                    }
                    
                    <div className="grid justify-center grid-cols-6 gap-4 lg:gap-6 mt-9">
                      <div className="col-span-6 sm:col-span-3">
                        <button
                          type="submit"
                      
                          className="bg-emerald-500 hover:bg-emerald-600 border border-emerald-500 transition-all rounded py-3 text-center text-sm  font-medium text-white flex justify-center w-full"
                        >
                          Save & Proceed{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              )}
            </div>
         
          </div>
          <OrderSummary />
        </div>
      </div>
    </div>
  );
}

export default Checkout;
