import React, { useState,useContext,useEffect} from "react";
import Modal from "../modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { isLoginAction } from "../../store/reducers/isOpenSlice";
import {
  addToCart,
  decreaseCart,
  incrementCart,
} from "../../store/reducers/cartSlice";
import CartContext from "../../context/cartContext";

function Card({ data }) {
  let [isOpen, setIsOpen] = useState(false);


  const dispatch = useDispatch();
  const navigate= useNavigate();
  const cart = useSelector((state) => state.cart);
  const cartContext = useContext(CartContext);
  const {
    addCartItem,
    decrementCartItemQuantity,
    incrementCartItemQuantity,
    cartList,
  } = cartContext;

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }



  const [cartQuantity, setCartQuantity] = useState(1);
  const [showQuantityControllers, setShowQuantityControllers] = useState(false);

  useEffect(() => {
    const logic = () => {
      const cartItem = cartList.filter(
        (eachCartItem) => eachCartItem.productId === data.productId._id
      );
      if (cartItem.length > 0 && cartItem[0].quantity >= 1) {
        setCartQuantity(cartItem[0].quantity);
        setShowQuantityControllers(true);
      } else {
        setCartQuantity(data.productId.minimumOrder);
        setShowQuantityControllers(false);
      }
    };
    logic();
  }, [cartList]);
  
//data

  const handleAddToCart = (product) => {
    // dispatch(addToCart(product));
    const loginToken = localStorage.getItem("loginToken")
    const userId = localStorage.getItem("userId")
    const secondStage = localStorage.getItem("secondStage")
    if(loginToken === null || userId === null ||  secondStage === null){
      dispatch(isLoginAction(true))
    }
    else{
      setShowQuantityControllers(true);
      addCartItem({
        ...product,
        price: product.currentPrice,
        productName: product.name,
      });
    }
  
  };

  const [clicksNumber, setClicksNumber] = useState(0)
  
  const percentageDiscount =
    ((data.productId.maxPrice - data.productId.currentPrice) /
      data.productId.maxPrice) *
    100;
  return (
    <>
      {" "}
      <div className="group box-border overflow-hidden flex rounded-md shadow-sm pe-0 flex-col items-center bg-white relative p-3">
        <div
          onClick={openModal}
          className="relative flex justify-center w-full cursor-pointer"
        >
          {percentageDiscount === 0 ? (
            ""
          ) : (
            <span className="absolute text-dark text-xs  bg-emerald-500 text-white py-1 px-2 rounded-xl font-medium z-10 left-2 top-1">
              {Math.ceil(percentageDiscount)}% Off
            </span>
          )}
          <div className="h-[200px] flex flex-col justify-center">

          
          <span
            style={{
              boxSizing: "borderBox",
              display: "inline-block",
              overflow: "hidden",
              width: "initial",
              height: "initial",
              background: "none",
              opacity: "1",
              border: "0px",
              margin: "0px",
              padding: "0px",
              position: "relative",
              maxWidth: "100%",
            }}
          >
          
            <img
              src={data.productId.images[0]}
              decoding="async"
              data-nimg="intrinsic"
              className="object-cover rounded-xl transition duration-150 ease-linear transform group-hover:scale-105"
              onClick={() => navigate("/product?id=" + data.productId._id)}
            
              style={{
                inset: "0px",
                boxSizing: "border-box",
                padding: "10px",
                border: "none",
                margin: "auto",
                display: "block",
                height:"200px",
               
                
                maxWidth: "200px",
                maxHeight: "200px", 
                width: "100%", 
                height: "auto", 
              }}
              
            />
          </span>
          </div>
        </div>

        <div className="flex justify-between w-full p-4 mt-5">
          <div>
            <h6>{data.productId.name}</h6>
            <p>
              ₹{data.productId.currentPrice.toLocaleString('en-IN')}{" "}
              <span className="line-through text-gray-500">
                ₹{data.productId.maxPrice.toLocaleString('en-IN')}
              </span>{" "}
            </p>
            <p>
             Stock :  {""}<span>{data.productId.stock} Units</span> 
            </p>
          </div>
        </div>
     
        {showQuantityControllers && (
          <div className="flex items-center justify-center  space-s-3 sm:space-s-4  w-full mb-2 px-6">
            <div className="group flex items-center justify-between rounded-md overflow-hidden flex-shrink-0 border h-10  md:h-10 border-gray-300 ">
            <>
              <button
              
              
                onClick={() => {
                  if (cartQuantity <= data.productId.minimumOrder) {
                    setShowQuantityControllers(false);
                  }
                  decrementCartItemQuantity(data.productId._id,data.productId.minimumOrder);
                  setClicksNumber(clicksNumber - 1)
                }}
                className="flex items-center justify-center flex-shrink-0 h-full transition ease-in-out duration-300 focus:outline-none w-8 md:w-12 text-heading border-e border-gray-300 hover:text-gray-500"
              >
                <span className="text-dark text-base">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </span>
              </button>
           
              <div className="font-semibold flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default flex-shrink-0 text-base text-heading w-8 md:w-20 xl:w-24 text-center"
             >{cartQuantity}  </div>

              <button
                onClick={ () => {
                  incrementCartItemQuantity(data.productId._id,)
                }}
                className="flex items-center justify-center h-full flex-shrink-0 transition ease-in-out duration-300 focus:outline-none w-8 md:w-12 text-heading border-s border-gray-300 hover:text-gray-500"
              >
                <span className="text-dark text-base">
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </span>
              </button>
            </>
            </div>
            </div>
          
        )} 
        {showQuantityControllers === false && data.productId.stock > 0 && (
          <button
            disabled={cartQuantity <= 0 ? true : false}
            onClick={() => {handleAddToCart(data.productId); setClicksNumber(data.productId.minimumOrder)}}
            ariallabel="chart"
            className={
              data.quantity === 0
                ? "!cursor-default h-9 w-9 flex items-center justify-center border border-gray-200 rounded text-emerald-500 hover:border-emerald-500 hover:bg-emerald-500 hover:text-white transition-all"
                : "!cursor-pointer h-9 w-9 flex items-center justify-center border border-gray-200 rounded text-emerald-500 hover:border-emerald-500 hover:bg-emerald-500 hover:text-white transition-all absolute bottom-2 right-2 transform"
            }
          >
            <span className="text-xl">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
            </span>
          </button>
        ) 
       }
      </div>
    </>
  );
}

export default Card;
