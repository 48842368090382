import React, { useEffect,useState,useRef } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerPostDetails } from "react-shimmer-effects";
import {BiSolidPencil} from "react-icons/bi"
import {  useNavigate } from "react-router-dom";
import plus from "../../assets/img/240_F_481134373_0W4kg2yKeBRHNEklk4F9UXtGHdub3tYk.jpg"
import  axios from "axios"

const SignupSchema = Yup.object().shape({
  fullName: Yup.string().required("Full Name is required!"),
  companyName: Yup.string().required("Company Name is required!"),
  companyAddress: Yup.string().required("Company Address is required!"),
  gstin: Yup.string(),
  image: Yup.mixed(),
  website :Yup.string()
});
const UpdateProfile = () => {
  let navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    const safeRoute =()=>{
      const loginToken = localStorage.getItem("loginToken")
      const userId = localStorage.getItem("userId")
      const secondStage = localStorage.getItem("secondStage")
      if(loginToken=== null || userId === null || secondStage === null){
        navigate("/")
      }
    }
    safeRoute()
  }, []);

  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

  const fileInputRef2 = useRef(null);
  const [userDetails,setUserDetails]= useState([])
  const [reload,setReload]= useState(false)
  const [showShimmer,setShowShimmer]= useState(true)
  const [selectedFile, setSelectedFile] = useState('')
  const [selectedImage,setSelectedImage]= useState(plus)

  
  

  useEffect(() => {
    const loggedUserDetails = async()=>{
      try{
        const apiResponse = await axios.post(`${baseUrl}/api/v1/profile/getUserProfile`,{
          authId:localStorage.getItem("userId")
        })
        setUserDetails(apiResponse.data[0])
        setSelectedImage(apiResponse.data[0].companyLogo === ""?plus:apiResponse.data[0].companyLogo)
        setShowShimmer(false)
      }
      catch(e){
        console.log(e)
      }
    }
    loggedUserDetails()
  }, [reload]);

  const handleImageClick = () => {
		fileInputRef2.current.click();
	};

  const handleImageUpload = async (event, index) => {
		const file = event.target.files[0];
		if (file) {
      setSelectedFile(file)
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

    }	
  }

  const updateUserDetails = async(data)=>{
    const {fullName,companyName,companyAddress,gstin,image,website}= data
    const cleanedName = fullName.replace(/\s+/g, '');
    const cleanedCompanyName = companyName.replace(/\s+/g, '');
    const cleanedCompanyAddress = companyAddress.replace(/\s+/g, '');
    
    const isValidGst = ()=>{
      if(gstin.length>0){
        const cleanedGstIn = gstin.replace(/\s+/g, '');
        if(cleanedGstIn===""){
          return false
        }
        else{
          return true
        }
      }
      else{
        return true
      }
    }

    const isValidWeb = ()=>{
      if(website.length>0){
        const cleanedWeb= website.replace(/\s+/g, '');
        if(cleanedWeb===""){
          return false
        }
        else{
          return true
        }
      }
      else{
        return true
      }
    }

    const validGst =  isValidGst()
    const validWeb = isValidWeb()
    


    if(cleanedName===""||cleanedCompanyName===""|| cleanedCompanyAddress===""){
      toast.warn('Please Provide Valid Input', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    else if (validGst === false){
        toast.warn('Please Provide Valid Gst No:', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          });
      }
     
    
    else if( validWeb=== false){
      toast.warn('Please Provide Valid Website Address', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
  
    else if(fullName.length>15){
      toast.warn('Name Should Be Less Than 15 Characters', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
    }
    else
    {
      try{
      
        const headers={
          authorization:localStorage.getItem("loginToken")
        }
        if(selectedFile){
          const formData = new FormData();
          formData.append("logo", selectedFile);
         
          const imgResponeFromApi = await axios.post(`${baseUrl}/api/v1/profile/addCompanyLogo`, formData,{
            headers,
          })
        setReload(!reload)
        }
     
  
        const updateDetailsWithOutImage = await axios.put(`${baseUrl}/api/v1/profile/updateUserProfile`,{
            "name": fullName,
            "companyName": companyName,
            "companyAddress": companyAddress,
            "authId": localStorage.getItem("userId"),
            "gst":gstin,
            "companyLogo": "",
            "website":website,
        },{
          headers,
        })
        toast.success(`User Details Updated successfully`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
        }
        )
         setReload(!reload)
  
        
      }
      catch(e){
        console.log(e)
        toast.warn(`Error Occurred While Updating`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        }
        )
      }

        }
    }


  if(showShimmer){
    return(
      <ShimmerPostDetails card cta variant="SIMPLE" />
    )
  }else{
    return (
      <div className="max-w-screen-2xl">
        <div className="md:grid md:grid-cols-3 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h2 className="text-xl font-semibold mb-5 text-black">
                Brand Logo
              </h2>
            </div>
          </div>
        </div>
  
        <Formik
         initialValues={{
          fullName: userDetails.name || "",
          companyName: userDetails.companyName || "",
          companyAddress: userDetails.companyAddress || "",
          gstin: userDetails.gstin || "",
          image: null,
          email:userDetails.authId.email||"",
          website:userDetails?.website||""
        }}
          validationSchema={SignupSchema}
          onSubmit={async (values) => {
            updateUserDetails(values)
          }}
          enableReinitialize
        >
          {({ errors, touched, values,
                   handleChange }) => {
            return (
              <Form className="flex flex-col justify-center">
                        <div className="grid grid-cols-1 gap-5">
                         
                      <div className='inline py-2 m-3 flex justify-center max-w-80'>
                        <span onClick={handleImageClick} style={{ position: 'relative' }}>
                  
                        <img
													src={selectedImage}
													alt='Selected'
													style={{ cursor: 'pointer' }}
													className='w-[300px] h-[200px] rounded border-1 border-black'
												/>
                          <BiSolidPencil size={24} className="text-emerald-500" style={{ position: 'absolute', top: '10', right: '10', cursor: 'pointer' }} />
                      
                        </span>
											
                       
												<input
													type='file'
													ref={fileInputRef2}
													accept='image/*'
													onChange={handleImageUpload}
													style={{ display: 'none' }}
													key={selectedImage}
												/>
											</div>	

                          <div className="grid">
                            <label
                              htmlFor="fullName"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              Full Name
                            </label>
                            <Field
                              className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="fullName"
                              name="fullName"
                              placeholder="Full Name"
                              type="text"
                              onChange={handleChange}
                             value={values.fullName}
                            />
                            {errors.fullName && touched.fullName && (
                              <span className="text-red-400 text-sm mt-2">
                                {errors.fullName}
                              </span>
                            )}
                          </div>
                          <div className="grid">
                            <label
                              htmlFor="fullName"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              Email
                            </label>
                            <Field
                              className="py-2 pl-2 w-full  bg-gray-400 text-black  appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="fullName"
                              name="fullName"
                              placeholder="Full Name"
                              type="text"
                              disabled="true"
                            
                              value = {values.email}
                            />
                            {errors.fullName && touched.fullName && (
                              <span className="text-red-400 text-sm mt-2">
                                {errors.fullName}
                              </span>
                            )}
                          </div>
                          <div className="grid">
                            <label
                              htmlFor="companyName"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              Company Name
                            </label>
                            <Field
                              className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="companyName"
                              name="companyName"
                              placeholder="Company Name"
                              type="text"
                              onChange={handleChange}
                             value={values.companyName}
                            />
                            {errors.companyName && touched.companyName && (
                              <span className="text-red-400 text-sm mt-2">
                                {errors.companyName}
                              </span>
                            )}
                          </div>
                          <div className="grid">
                            <label
                              htmlFor="companyAddress"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              Company Address
                            </label>
                            <Field
                              className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="companyAddress"
                              name="companyAddress"
                              placeholder="Company Address"
                              onChange={handleChange}
                              type="text"
                               value={values.companyAddress}
                            />
                            {errors.companyAddress && touched.companyAddress && (
                              <span className="text-red-400 text-sm mt-2">
                                {errors.companyAddress}
                              </span>
                            )}
                          </div>
                          <div className="grid">
                            <label
                              htmlFor="fullName"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              Website Address (Optional)
                            </label>
                            <Field
                              className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="website"
                              name="website"
                              placeholder="Website"
                              type="text"
                              onChange={handleChange}
                             value={values.website}
                            />
                           
                          </div>
                          <div className="grid">
                            <label
                              htmlFor="gstin"
                              className="block text-gray-500 font-medium text-sm leading-none mb-2 "
                            >
                              GSTIN (Optional)
                            </label>
                            <Field
                              className="py-2 pl-2 w-full appearance-none border text-sm opacity-75 text-input rounded-md placeholder-body min-h-12 transition duration-200 focus:ring-0 ease-in-out bg-white border-gray-200 focus:outline-none focus:border-emerald-500 h-11 md:h-12"
                              id="gstin"
                              name="gstin"
                              placeholder="GSTIN"
                              type="text"
                               onChange={handleChange}
                               value={values.gstin}
                            />
                            
                          </div>
                          <button
                            type="submit"
                            className="w-full text-center py-3 rounded bg-emerald-500 text-white hover:bg-emerald-600 transition-all focus:outline-none my-1"
                          >
                            Update Profile Details
                          </button>
                        </div>
                      </Form>
            );
          }}
        </Formik>
      </div>
    );
    
  }
  


 
};

export default UpdateProfile;
