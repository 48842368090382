import React, { useEffect } from 'react'
import Table from '../table/Table'
import { Link, useNavigate } from "react-router-dom";



const MyOrders = () => {
  let navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    const safeRoute =()=>{
      const loginToken = localStorage.getItem("loginToken")
      const userId = localStorage.getItem("userId")
      const secondStage = localStorage.getItem("secondStage")
      if(loginToken=== null || userId === null || secondStage === null){
        navigate("/")
      }
    }
    safeRoute()
  }, []);
  return (

    <div className="overlow-hidden">
      <h2 className="text-xl text-black font-semibold mb-5">My Orders</h2>
      <Table />
    </div>
  )
}

export default MyOrders