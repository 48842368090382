import { useEffect, useState, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { isLoginAction } from "../../store/reducers/isOpenSlice";
import { ShimmerPostDetails } from "react-shimmer-effects";
import {AiOutlineDownload} from "react-icons/ai"

import CartContext from "../../context/cartContext.js";
import { Autoplay, Pagination, Navigation } from "swiper";
import axios from "axios";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Card from "../card/Card";

function Product() {
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  const cartContext = useContext(CartContext);
  const [showShimmer,setShowShimmer]= useState(true)
  const navigate = useNavigate()
  const {
    addCartItem,
    decrementCartItemQuantity,
    incrementCartItemQuantity,
    cartList,
  } = cartContext;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [clicksNumber, setClicksNumber] = useState(0)
  const dispatch = useDispatch();
  const location = useLocation();

  const [productDetails, setProductDetails] = useState({});
  let productId=""
  useEffect(() => {
    const url = window.location.href;
    const urlObject = new URL(url);
    productId = urlObject.searchParams.get("id");

    const productDetailsApi = async () => {
      try {
        const productDetails = await axios.post(
          `${baseUrl}/api/v1/products/fetchProductDetails`,
          {
            _id: productId,
          }
        );
        setProductDetails(productDetails.data);
        setShowShimmer(false)
      } catch (e) {
        // alert("error occured while fetching product data");
        console.log(e)
      }
    };
    productDetailsApi();
  }, [location.search]);

  const [cartQuantity, setCartQuantity] = useState(1);
  const [showQuantityControllers, setShowQuantityControllers] = useState(false);

  useEffect(() => {
    const logic = () => {
      const cartItem = cartList.filter(
        (eachCartItem) => eachCartItem.productId === productDetails._id
      );
      if (cartItem.length > 0 && cartItem[0].quantity > 0) {
        setCartQuantity(cartItem[0].quantity);
        setShowQuantityControllers(true);
      } else {
        setCartQuantity(productDetails.minimumOrder);
        setShowQuantityControllers(false);
      }
    };
    logic();
  }, [cartList, productDetails]);


  const handleAddToCart = (productDetails) => {
    setShowQuantityControllers(true);
    addCartItem({
      ...productDetails,
      price: productDetails.currentPrice,
      productName: productDetails.name,
    });
  };

  const percentageDiscount =
    ((productDetails.maxPrice - productDetails.currentPrice) /
      productDetails.maxPrice) *
    100;
if(showShimmer){
      return(
        <>
        <ShimmerPostDetails card cta variant="SIMPLE" />
     <ShimmerPostDetails card cta variant="EDITOR" />
    </> 
    
      )
     }
     else{
      return (
        <>
          {Object.keys(productDetails).length != 0 > 0 ? (
            <div className="bg-gray-50">
              <div className="px-0 py-10 lg:py-10">
                <div className="mx-auto px-3 lg:px-10 max-w-screen-2xl">
                  <div className="flex items-center pb-4">
                    <ol className="flex items-center w-full overflow-hidden">
                      <li className="text-sm pr-1 transition duration-200 ease-in cursor-pointer  font-semibold">
                        <Link
                          className="!no-underline !text-black hover:!text-emerald-500"
                          to="/"
                        >
                          Home
                        </Link>
                      </li>
    
                      <li className="text-sm mt-[1px]">
                        <svg
                          stroke="currentColor"
                          fill="none"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <polyline points="9 18 15 12 9 6"></polyline>
                        </svg>
                      </li>
                      <li className="text-sm px-1 transition duration-200 ease-in ">
                        {productDetails.name}
                      </li>
                    </ol>
                  </div>
                  <div className="w-full rounded-lg p-3 lg:p-12 bg-white">
                    <div className="flex flex-col xl:flex-row">
                      <div className="flex-shrink-0 xl:pr-10 lg:block w-full mx-auto md:w-6/12 lg:w-5-12 xl:w-4/12 ">
                        {percentageDiscount === 0 ? (
                          ""
                        ) : (
                          <span className=" text-dark text-sm bg-emerald-500 text-white py-1 px-2 rounded font-medium z-10 right-4 top-4">
                            {Math.ceil(percentageDiscount)}% Off
                          </span>
                        )}
    
    
                        <Swiper
                           spaceBetween={30}
                            loop={true}
                            centeredSlides={true}
                            autoplay={{
                              delay: 2000,
                              disableOnInteraction: false,
                            }}
                            pagination={{
                              clickable: true,
                            }}
                            modules={[Pagination, Autoplay]}
                            className="mySwiper"
                          >
    
                            {
                              productDetails&&productDetails.images ? 
                        productDetails.images.map((data,index)=>{
                                return(
                                  <SwiperSlide
                                  key={index}
                          className="rounded-lg relative h-3/6 overflow-hidden"
                        >
                          <div className="text-sm text-gray-600 hover:text-emerald-dark">
                            <div
                              style={{
                                boxSizing: " border-box",
                                display: "block",
                                overflow: "hidden",
                                width: "initial",
                                height: "initial",
                                background: "none",
                                opacity: "1",
                                border: " 0px",
                                margin: "0px",
                                padding: "0px",
                                position: "relative",
                                // padding: "60.1053% 0px 0px",
                              }}
                              className="p-28 md:p-48"
                            >
                              <span
                                style={{
                                  boxSizing: "border-box",
                                  display: "block",
                                  width: "initial",
                                  height: "initial",
                                  background: "none",
                                  opacity: " 1",
                                  border: "0px",
                                  margin: "0px",
                                  // padding: "60.1053% 0px 0px",
                                  // "@screen md": {
                                  //   padding: "30.1053% 0px 0px", // Adjust the value for medium screens
                                  // },
                                  
                                }} className="p-28 md:p-48"
                              ></span>

                              <img
                                alt="Quality Freshness Guaranteed!"
                                // sizes="100vw"
                                onClick={()=>{
                                  window.location.href=data
                                }}
                                src={data}
                                decoding="async"
                                data-nimg="responsive"
                                className="object-cover"
                                style={{
                                  position: "absolute",
                                  inset: "0px",
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  border: "none",
                                  margin: "auto",
                                  display: "block",
                                  width: "0px",
                                  height: "0px",
                                  minWidth: "100%",
                                  maxWidth: "80%",
                                  minHeight: "100%",
                                  maxHeight: "100%",
                                  padding:"2rem",
                                  objectFit: "contain",
                                }}
                              />
                                 {/* < AiOutlineDownload size={26}  className="text-emerald-500 cursor-pointer" title="Download Image"/> */}
                            </div>
                          </div>
                                 </SwiperSlide>
    
                                )
                              }):""
                            }
                         
    
    
                          </Swiper>
                          
    
                        {/* <span
                          style={{
                            boxSizing: "border-box",
                            display: "block",
                            overflow: " hidden",
                            width: " initial",
                            height: "initial",
                            background: "none",
                            opacity: "1",
                            border: "0px",
                            margin: "0px",
                            padding: "0px",
                            position: "relative",
                          }}
                        >
                          <img
                            alt={productDetails.name}
                            src={productDetails.images[0]}
                            sizes="100vw"
                          />
                        </span> */}
                      </div>
                      <div className="w-full">
                        <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row">
                          <div className="w-full md:w-7/12 md:pr-4 lg:pr-4 xl:pr-12">
                            <div className="mb-6 flex justify-between items-center">
                              <h1 className="leading-7 text-lg md:text-xl lg:text-2xl mb-1 font-semibold text-gray-800">
                                {productDetails.name}
                              
                              </h1>
                              {/* < AiOutlineDownload size={26}  className="text-emerald-500 cursor-pointer" title="Download Image"/> */}
                            </div>
                            <div className="font-bold">
                              <span className="inline-block text-2xl">
                                ₹{productDetails.currentPrice.toLocaleString('en-IN')}
                              </span>
                              {productDetails.maxPrice ===
                              productDetails.currentPrice ? (
                                ""
                              ) : (
                                <del className="text-lg font-normal text-gray-400 ml-1">
                                  ₹{productDetails.maxPrice.toLocaleString('en-IN')}
                                </del>
                              )}
                            </div>
                            <div className="mb-4 md:mb-5 block">
                              {productDetails.stock !== 0 && (
                                <>
                                <span className="bg-emerald-100 text-emerald-600 rounded-full inline-flex items-center justify-center px-2 py-1 text-xs font-semibold mt-2 ">
                                  In Stock 
                                </span>
                                <h6 className="mt-5"> Stock Available : {productDetails.stock} Units</h6>
                                </>
                              )}
                              {productDetails.stock === 0 && (
                                <span className="bg-red-100 text-red-600 rounded-full inline-flex items-center justify-center px-2 py-1 text-xs font-semibold  mt-2">
                                  Out Of Stock
                                </span>
                              )}
                            </div>
                            <h5>Description</h5>
                              <p className="text-sm leading-6 text-gray-500 md:leading-7 my-3">
                                {productDetails.description}
                              </p>
                            <div>
                              <h5>Key Features</h5>
                              <ul className="list-disc my-3 ">
                                {productDetails.keyFeatures.map((each) => (
                                  <li className="ml-5">{each}</li>
                                ))}
                              </ul>
                              <h5>Specifications</h5>
                              <table className="w-full border-collapse my-3">
                                <tbody>
                                  {Object.entries(productDetails.specification).map(
                                    ([key, value], index) => (
                                      <tr key={index} className="">
                                        <td className="p-2 font-semibold">{key}</td>
                                        <td className="p-2">{value}</td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                              {/* <div>
                                <h6>Stock: {productDetails.stock} Units</h6>
                              </div> */}
                              
    
                              <div className="bg-gray-50">
                                <div className="flex items-center mt-4 ">
    
                                <button
                                      disabled={productDetails.quantity === 0}
                                      onClick={() => {
                                        const loginToken = localStorage.getItem("loginToken")
                                        const userId = localStorage.getItem("userId")
                                        const secondStage = localStorage.getItem("secondStage")
                                        if(loginToken === null || userId === null ||  secondStage === null){
                                          dispatch(isLoginAction(true))
                                        }
                                        else{
                                          navigate(`/catalog1?id=${productDetails._id}&price=${productDetails.currentPrice}`)
                                        }
                                      }
                                      //  handleAddToCart(productDetails)
                                      }
                                      className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold text-center justify-center border-2 border-emerald-500  rounded-md focus-visible:outline-none focus:outline-none text-emerald-600 px-4  mr-2 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-white-500 hover:bg-emerald-600 w-full h-12"
                                    >
                                      Add To Catalog
                                    </button>
                                  {showQuantityControllers && (
                                    <div className="flex items-center justify-between space-s-3 sm:space-s-4 w-full">
                                      <div className="group flex items-center justify-between rounded-md overflow-hidden flex-shrink-0 border h-11 md:h-12 border-gray-300">
                                        <button
                                        // disabled={cartQuantity <= 0 ? true : false}
                                        disabled={cartQuantity <  productDetails.minimumOrder? true : false}
                                          onClick={() => {
                                            if (cartQuantity <= 1) {
                                              setShowQuantityControllers(false);
                                            }
                                            decrementCartItemQuantity(
                                              productDetails._id,
                                              productDetails.minimumOrder
                                            );
                                            setClicksNumber(clicksNumber - 1)
                                          }}
                                          className="flex items-center justify-center flex-shrink-0 h-full transition ease-in-out duration-300 focus:outline-none w-8 md:w-12 text-heading border-e border-gray-300 hover:text-gray-500"
                                        >
                                          <span className="text-dark text-base">
                                            <svg
                                              stroke="currentColor"
                                              fill="none"
                                              strokeWidth="2"
                                              viewBox="0 0 24 24"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                              
                                            >
                                              <line
                                                x1="5"
                                                y1="12"
                                                x2="19"
                                                y2="12"
                                              ></line>
                                            </svg>
                                          </span>
                                        </button>
                                        <div  className="font-semibold text-center  flex items-center justify-center h-full transition-colors duration-250 ease-in-out cursor-default flex-shrink-0 text-base text-heading w-8 md:w-20 xl:w-24" > {cartQuantity} </div>
                                        
                                      
                                        <button
                                        
                                          
                                          onClick={  () => {
                                            incrementCartItemQuantity(productDetails._id,)
                                          
                                          }}
                                          className="flex items-center justify-center h-full flex-shrink-0 transition ease-in-out duration-300 focus:outline-none w-8 md:w-12 text-heading border-s border-gray-300 hover:text-gray-500"
                                        >
                                          <span className="text-dark text-base">
                                            <svg
                                              stroke="currentColor"
                                              fill="none"
                                              strokeWidth="2"
                                              viewBox="0 0 24 24"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                              height="1em"
                                              width="1em"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <line
                                                x1="12"
                                                y1="5"
                                                x2="12"
                                                y2="19"
                                              ></line>
                                              <line
                                                x1="5"
                                                y1="12"
                                                x2="19"
                                                y2="12"
                                              ></line>
                                            </svg>
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  )} 
                                  
                                  { showQuantityControllers === false && (
                                    <button
                                      disabled={productDetails.quantity === 0}
                                      onClick={() =>{
                                        const loginToken = localStorage.getItem("loginToken")
                                        const userId = localStorage.getItem("userId")
                                        const secondStage = localStorage.getItem("secondStage")
                                        if(loginToken === null || userId === null ||  secondStage === null){
                                          dispatch(isLoginAction(true))
                                        }
                                        else{
                                          handleAddToCart(productDetails)
                                        }
                                      }
                                      }
                                      className="text-sm leading-4 inline-flex items-center cursor-pointer transition ease-in-out duration-300 font-semibold text-center justify-center border-0 border-transparent rounded-md focus-visible:outline-none focus:outline-none text-white px-4 mr-4 md:px-6 lg:px-8 py-4 md:py-3.5 lg:py-4 hover:text-white bg-emerald-500 hover:bg-emerald-600 w-full h-12"
                                    >
                                      Add To Cart
                                    </button>
                                  )}
    
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p>sorry no details found</p>
          )}
        </>
      );

     }

  
}

export default Product;
