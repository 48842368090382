import React, { useEffect,useState } from "react";
import Categories from "../components/categories/Categories";
import FastDeliveryCard from "../components/fastDeliveryCard/FastDeliveryCard";
import LatestDiscountCouponCode from "../components/latestDiscountCouponCode/LatestDiscountCouponCode";
import PopularProduct from "../components/popularProduct/PopularProduct";

import OrganicProductCard from "../components/organicProductCard/OrganicProductCard.js";
import Slider from "../components/slider/Slider";
import LatestDiscountedProduct from "../components/latestDiscountedProduct/LatestDiscountedProduct";
import Cart from "../components/cart/Cart";
import { Coupon } from "../fakeData/coupon";

function Home() {
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);
  
const twoCupon=Coupon.slice(0,2)
return (
  <div className="bg-gray-50">
    <div className="min-h-screen">
      <Cart />
      <div className="bg-white">
        <div className="mx-auto py-5 px-5 max-w-screen-2xl">
          <div className="flex w-full px-2 h-1/4">
            <div className="w-full h-3/6">
              <Slider />
            </div>
          </div>
          <OrganicProductCard />
        </div>
      </div>
      <Categories />
      <div id="popular">
      <PopularProduct  />

      </div>
   
     

 
      
      <FastDeliveryCard />
    </div>
  </div>
);
}

export default Home;
