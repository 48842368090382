import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { searchAction } from "../../store/reducers/searchSlice";
import axios from "axios";

function Categories() {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
    case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
    case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
    case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }
  
 
  const handleClick = (value) => {
    navigate("/search?Category=" + value);

    dispatch(searchAction({ value: value, path: "Category" }));
  };
  const [CategoriesData, setCategoriesData] = useState([]);

  useEffect(() => {
    const getCategories = async () => {
    
      try {
        const apiResponse = await axios.get(
          `${baseUrl}/api/v1/products/fetchAllCategories`
        );
        const activeCatageories = apiResponse.data.filter((each)=>{
          if(each.status==="ACTIVE"){
            return each
          }
        })
        setCategoriesData(activeCatageories);
      } catch (e) {
        // alert("error occured while fetching categories data");
      }
    };
    getCategories();
  }, []);

  const [allProducts, setAllProducts] = useState([]);
  useEffect(() => {
    const getAllProducts = async () => {
      try {
        const apiResponse = await axios.get(
          `${baseUrl}/api/v1/products/fetchAllProducts`
        );
        if(apiResponse && apiResponse.data.length>0){
          const activeProducts = apiResponse.data.filter((each)=>{
            if(each.status === "ACTIVE"){
              return each
            }
           })
           setAllProducts(activeProducts);
          }
      
      } catch (e) {
        // alert("error occured while fetching all products data");
        console.log(e)
      }
    };
    getAllProducts();
  }, []);

  return (
    <div className="bg-gray-100 lg:py-16 py-10">
      <div className="mx-auto max-w-screen-2xl px-3 sm:px-10">
        <div className="mb-10 flex justify-center">
          <div className="text-center w-full lg:w-2/5">
            <h2 className="text-xl lg:text-2xl mb-2  font-semibold">
              Featured Categories
            </h2>
            <p className="text-base font-sans text-gray-600 leading-6">
              Choose your necessary products from this featured categories.
            </p>
          </div>
        </div>
        <div className="flex  justify-center flex-wrap grow-0 flex-row">
          {CategoriesData.map((category, index) => {
            return (
              <div className="group">
                <Link
                  className="!no-underline !text-black group-hover:!text-emerald-500 cursor-pointer "
                  to={"/search?Category=" + category._id}
                >
                  <div
                    style={{
                      backgroundImage: `URL(${category.image})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center center",
                      borderRadius: "15px",
                    }}
                    key={index}
                    className="h-32 w-24 m-2 flex flex-col justify-end text-center  md:w-44 border bg-white p-4 cursor-pointer transition duration-200 ease-linear transform group-hover:shadow-2xl group-hover:border-emerald-500 group-hover:border-2"
                  >
                    {/* <h2 className="text-sm  font-medium leading-tight line-clamp-1 ">
                      {category.name}
                    </h2> */}
                  </div>
                </Link>
                <h2 className="text-sm  text-center text-black font-semibold ">
                      {category.name}
                    </h2>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Categories;
