import React from 'react';
import {AiOutlineDoubleRight,AiOutlineSwapRight,AiFillCaretRight,AiOutlineRight} from "react-icons/ai"
import  { useState, useEffect } from 'react';
import { useParams,useNavigate,useLocation } from "react-router-dom";
import { ShimmerPostDetails } from "react-shimmer-effects";
//toasts
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaPlus,FaTrash,FaRegFilePdf } from 'react-icons/fa'
import { FaRupeeSign, FaPercent } from "react-icons/fa";
import { RiDeleteBin6Line, RiDownload2Line } from 'react-icons/ri';


//popups
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
//Network
import axios from 'axios';

import {NoSavedCatalogsFound,NoProductsFound,Dummy} from "../catalogsFallbacks/catalogFallbacks"
import { reach } from 'yup';

const Catalog1 = () => {

let baseUrl = "";
switch (process.env.REACT_APP_STATUS) {
    case "DEV":
    baseUrl = process.env.REACT_APP_DEV_URL;
    break;
    case "STAGE":
    baseUrl = process.env.REACT_APP_STAGE_URL;
    break;
    case "PROD":
    baseUrl = process.env.REACT_APP_PROD_URL;
    break;
}
const [marginType, setMarginType] = useState("percent");
const [marginPrice, setMarginPrice] = useState(0);
const [open, setOpen] = useState(false); 

const [userCatalogs,setUserCatalogs]=useState([])
const [newCatalogName,setNewCatalogName]= useState("")
const [showPopup,setShowPopup] = useState(false)
const [selectedCatalog,setSelectedCatalog]= useState("")
const [reload,setReload]=useState(false)
const navigate = useNavigate()
const [showShimmer,setShowShimmer] = useState(true)

const url = window.location.href;
const urlObject = new URL(url);

const [id,setId]= useState(urlObject.searchParams.get("id"))
const [price, setPrice]= useState(urlObject.searchParams.get("price"))

// const id = urlObject.searchParams.get("id");
// const price = ;

const handleWheel = (event) => {
    event.target.blur(); // Blur the input element
  };


 

useEffect(()=>{
  const fetchUserCatalogs = async()=>{

    const headers={
      authorization:localStorage.getItem("loginToken")
    }
    try{
      const userCatalogsResponse = await axios.get(`${baseUrl}/api/v1/catalog/fetchMyCatalog`,{
        headers,
      })
      const userCatalogsWithItems=[]
       for (let i=0; i<userCatalogsResponse.data.length; i++){
          const catalogDetailsResponse = await axios.post(`${baseUrl}/api/v1/catalog/fetchProductsFromCatalogId`,{   
          "catalogId": userCatalogsResponse.data[i]._id,
          "_id": localStorage.getItem("userId")
          },{
              headers,
          })
          userCatalogsWithItems.push({...userCatalogsResponse.data[i],items:catalogDetailsResponse.data.length,products:catalogDetailsResponse.data})

       }
     
      setUserCatalogs(userCatalogsWithItems)
      setShowShimmer(false)
    }
    catch(e){
      console.log(e)
      setShowShimmer(false)
    }
  }
     fetchUserCatalogs()
  },[reload])


  useEffect(() => {
    window.scrollTo(0, 0);
    const safeRoute =()=>{
      const loginToken = localStorage.getItem("loginToken")
      const userId = localStorage.getItem("userId")
      const secondStage = localStorage.getItem("secondStage")
      if(loginToken=== null || userId === null || secondStage === null){
        navigate("/")
      }
    }
    safeRoute()
  }, []);

  const createCatalog = async()=>{
    const cleanedName = newCatalogName.replace(/\s+/g, '');
  
     if(cleanedName === ""){
      toast.warn('Please Add Catalog Name', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored", 
        });
     }else{
      
     try{
      const headers ={
          authorization:localStorage.getItem("loginToken")
      }

      const createCatalogResponse = await axios.post(`${baseUrl}/api/v1/catalog/createCatalog`,
      {
        "logo": "URL",
        "name": newCatalogName,
        "color": "#1f1f1f",
        "font": "Sans",
        "designId": "#DESIGN001",
        "_id": localStorage.getItem("userId")
    }
      ,{
          headers,
      })
     
      toast.success(`${newCatalogName} Catalog Created Successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          background: 'rgb(5 150 105)', // Your custom color
          color: '#ffffff', // Text color
          fontWeight: 'bold', // Font weight
        },
        });
        setReload(!reload)
        setShowPopup(false)
        setNewCatalogName("")  
   }
   catch(e){
    console.log(e)
    toast.error(e.response.data.msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored", 
      });
   }
     }
  }
     //adding products into the catalog 
const handleAddProduct = async () => {
        const margin = parseInt(marginPrice)
     
    
        if(margin <= 0 || isNaN(margin)){
          toast.warn("Margin Should Be Greater Than 0", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored", 
            });
    
        }
       
        else{
          try{
            const headers={
              authorization:localStorage.getItem("loginToken")
            }
           
            const productPrice = parseInt(price)
            const sellingPrice = marginType === "percent" ? (productPrice*margin/100 + productPrice) : productPrice+margin
            const addingProductToCatalog = await axios.post(`${baseUrl}/api/v1/catalog/addProductsToCatalog`,{
              "productId":id,
              "catalogId":selectedCatalog,
              sellingPrice,
              "_id": localStorage.getItem("userId")
          },{
            headers,
          })
      
        
          toast.success(`Product Added To Catalog Successfully`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            style: {
              background: 'rgb(5 150 105)', // Your custom color
              color: '#ffffff', // Text color
              fontWeight: 'bold', // Font weight
            },
            });
            setOpen(false); 
            setId(null)
            setPrice(null)
            setReload(!reload)
            setMarginPrice(0)
            
          }
          catch(e){
            console.log(e)
            toast.error("Error Occurred", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored", 
              });
          }
        }
        
      };

      if(showShimmer){
        return(
          <ShimmerPostDetails card cta variant="SIMPLE" />
        )
      }else{
        return (
          <div className="bg-[#F3F4F6] min-h-screen p-2 md:px-10 shadow-2xl">
            <div className="container mx-auto   p-4  min-h-screen text-black  ">
              <div className="flex justify-between items-center mb-12 mt-4">
                <h4 className="text-3xl  font-semibold">Recent Catalogs</h4>
                <div className='flex flex-col justify-between h-20'>
                <button className="bg-emerald-500 mb-2  text-white px-4 py-2 rounded-lg" onClick={()=>setShowPopup(true)}>
                  Create a Catalog
                </button>
                <a className=" w-full !no-underline mt-2 text-center !text-white bg-emerald-500 text-white px-3 py-2 rounded-lg" target='_blank' href={`https://ls-sgpl-bucket-prod-mi-001.s3.ap-south-1.amazonaws.com/pdf/SHREERAJGIFTSCATALOG.pdf`} title='Download All Products'>
                 All Products
                </a>
                </div>
              
              </div>
             
              <div className=  {userCatalogs.length>0 ? "space-y-4 mt-6  cursor-pointer h-96 overflow-auto":""}>
                {userCatalogs.length>0 ?
                
                userCatalogs.map((catalog) => {
                  const timestamp = catalog.createdTimeStamp;
                  const dateObject = new Date(timestamp);
                  const day = dateObject
                    .getUTCDate()
                    .toString()
                    .padStart(2, "0");
                  const month = (dateObject.getUTCMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = dateObject.getUTCFullYear();
                  const formattedDate = `${day}/${month}/${year}`;
                
               return (
                  <div
                    key={catalog._id}
                    className="bg-white p-4 rounded-lg shadow-md flex justify-between"
                    onClick={
                      ()=>{ 
                       
                          const isInCatalog =  userCatalogs.filter((eachCatalog)=>{
                              if(eachCatalog._id === catalog._id){
                                  const productInCatalog =  eachCatalog.products.map((eachProduct)=>{
                                      if(eachProduct.productId === id){
                                          return true
                                      }
                                  })
                                 
                                  if(productInCatalog.includes(true) === true){
                                      return true
                                  }
                              } })
                            
                              
                              
                      if(id === null && price === null ){
                          navigate(`/catalog2/?id=${catalog._id}`)
                      }
                      else if(isInCatalog[0]) {
                              toast.warn("Product Already In The Catalog", {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored", 
                                })
                      }
                      else{
                          setSelectedCatalog(catalog._id)
                          setOpen(true)
                      }  
                    }}
                  >
                  <div className='w-80'>
                      <h2 className="text-xl font-semibold mb-2 w-80 overflow-auto">{catalog.name}</h2>
                    <p className="text-gray-600 mb-2">
                      Items: {catalog.items}
                    </p>
                    <p className="text-gray-600">
                      Created On: {formattedDate}
                    </p>
                          
                  </div>
                      <div className='self-center mr-5 flex '>
                          <AiOutlineRight size={23} className='text-emerald-500' />
                       </div>
                   
                  
                  </div>
                )
                }):<NoSavedCatalogsFound/>}
              </div>
            </div>
      
               {/* new */}
               <Popup
              modal
              nested
              onClose={() => {setOpen(false)
                setMarginPrice(0)}}
              open={open} // Set the open state
              closeOnDocumentClick={false}
              contentStyle={{ width: "70%", maxWidth: "400px", padding: "20px" }} // Adjust width and padding as needed
            >
              {(close) => (
                <div className="">
                  <h2 className="text-xl font-semibold mb-4">
                    Add Product To Catalog
                  </h2>
      
                  {/* Margin Type Radio Buttons */}
                  <div className="flex items-center mb-3">
                    <label className="mr-3 cursor-pointer">
                      <input
                        type="radio"
                        value="percent"
                        checked={marginType === "percent"}
                        onChange={() => setMarginType("percent")}
                      />
                      <span className="ml-1">Percentage</span>
                    </label>
                    <label className="cursor-pointer">
                      <input
                        type="radio"
                        value="rupee"
                        checked={marginType === "rupee"}
                        onChange={() => setMarginType("rupee")}
                      />
                      <span className="ml-1">Rupee</span>
                    </label>
                  </div>
      
                  {/* Margin Price Input */}
                  <div className="flex items-center mb-3">
                    <input
                      type="number"
                      min="0"
                      placeholder="Enter margin price"
                      className="flex-grow bg-gray-200 text-gray-800 p-2 rounded-l-md focus:outline-none border-2  no-scroll-input"
                      value={marginPrice}
                      onWheel={handleWheel}
                      onChange={(e) => {
                        setMarginPrice(e.target.value);
                      }}
                    />
                    <span className="px-3">
                      {marginType === "percent" ? <FaPercent /> : <FaRupeeSign />}
                    </span>
                  </div>
      
                  {/* Buttons */}
                  <div className="flex justify-between">
                    <button
                      className="w-1/2 bg-gray-300 text-gray-800 py-2 rounded"
                      onClick={close}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-1/2 bg-emerald-500 text-white py-2 rounded ml-4"
                      onClick={handleAddProduct}
      
                    >
                      Add Product
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          
            <Popup
              modal
              nested
              onClose={() => 
                {setShowPopup(false) 
                  setNewCatalogName("")}}
              open={showPopup} // Set the open state
              closeOnDocumentClick={true}
              contentStyle={{ width: "70%", maxWidth: "400px", padding: "20px" }}
            >
              {(close) => (
                <div className="">
                  <h2 className="text-xl font-semibold mb-4">Create a New Catalog</h2>
                  <div className="flex items-center mb-3">
                    <input
                      type="text"
                      placeholder="Name"
                      className="flex-grow bg-gray-200 text-gray-800 p-2 rounded-l-md focus:outline-none border-2 no-scroll-input"
                      value={newCatalogName}
                      onChange={(e) => {
                        setNewCatalogName(e.target.value);
                      }}
                    />
                
                  </div>
      
                  {/* Buttons */}
                  <div className="flex justify-between">
                    <button
                      className="w-1/2 bg-gray-300 text-gray-800 py-2 rounded"
                      onClick={close}
                    >
                      Cancel
                    </button>
                    <button
                      className="w-1/2 bg-emerald-500 text-white py-2 rounded ml-4"
                      onClick={createCatalog}
                    >
                      Create
                    </button>
                  </div>
                </div>
              )}
            </Popup>
          </div>
        );

      }
 
};

export default Catalog1;
