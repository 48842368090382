import React,{useEffect,useState,useRef} from 'react';
import { RiDeleteBin6Line, RiDownload2Line } from 'react-icons/ri';
import {BsFiletypePdf} from "react-icons/bs"
import{CgArrowsExchangeV} from "react-icons/cg"
import { useParams,useNavigate,useLocation, Link  } from "react-router-dom";
import {NoSavedCatalogsFound,NoProductsFound,Dummy} from "../catalogsFallbacks/catalogFallbacks"
import axios from "axios"
import { ShimmerPostDetails } from "react-shimmer-effects";

//toasts
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//icons
import { FaPlus,FaTrash,FaRegFilePdf } from 'react-icons/fa'
import { FaRupeeSign, FaPercent } from "react-icons/fa";
import{ AiOutlineRight,AiFillRightCircle, AiOutlineMenu } from 'react-icons/ai'
import {BiDotsVerticalRounded} from "react-icons/bi"

//popups
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";


const Catalog2 = () => {
  const products = [
    {
      id: 1,
      name: 'Product 1',
      image: 'product1.jpg',
    },
    {
      id: 2,
      name: 'Product 2',
      image: 'product2.jpg',
    },
    // Add more products here...
  ];
  const handleWheel = (event) => {
    event.target.blur(); // Blur the input element
  };
  let baseUrl = "";
  switch (process.env.REACT_APP_STATUS) {
      case "DEV":
      baseUrl = process.env.REACT_APP_DEV_URL;
      break;
      case "STAGE":
      baseUrl = process.env.REACT_APP_STAGE_URL;
      break;
      case "PROD":
      baseUrl = process.env.REACT_APP_PROD_URL;
      break;
  }

const navigate = useNavigate()
const location = useLocation();
const queryParams = new URLSearchParams(location.search);
const id = queryParams.get("id");

const menuRef = useRef(null);

const [catalogDetails,setCatalogDetails]= useState([])
const [catalogProducts,setCatalogProducts]= useState([])
const [updateMarginType, setUpdateMarginType] = useState("percent");
const [updateMarginPrice, setUpdateMarginPrice] = useState(0);
const [updateOpen, setUpdateOpen] = useState(false);
const [updateProductId,setUpdateProductId]= useState("")
const [showDeleteProduct,setShowDeleteProduct]= useState(false)
const [isMenuOpen, setIsMenuOpen] = useState(false);
const [showDeleteCatalog,setShowDeleteCatalog]= useState(false)
const [checkboxesVisible, setCheckboxesVisible] = useState(false);
const [selectedProducts, setSelectedProducts] = useState([]);
const [bulkUpdateList, setBulkUpdateList] = useState([]);
const [reload,setReload]= useState(false)
const [bulkMarginPrice,setBulkMarginPrice]= useState(0)
const [bulkOpen,setBulkOpen] = useState(false)

const [bulkMarginType,setBulkMarginType] = useState("percent")
const [bulkDeleteList,setBulkDeleteList]= useState([])
const [isBulkDeletedClicked,setIsBulkDeletedClicked] = useState(false)
const [showBulkDelete,setShowBulkDelete] = useState(false)
const [showShimmer,setShowShimmer] = useState(true)


const [bulkMargin,setBulkMargin]= useState("")

useEffect(() => {
  window.scrollTo(0, 0);
  const safeRoute =()=>{
    const loginToken = localStorage.getItem("loginToken")
    const userId = localStorage.getItem("userId")
    const secondStage = localStorage.getItem("secondStage")
    if(loginToken=== null || userId === null || secondStage === null){
      navigate("/")
    }
  }
  safeRoute()
}, []);

const closeMenu = () => {
  setIsMenuOpen(false);
};
useEffect(() => {
  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      // Click occurred outside the menu, so close it
      closeMenu();
    }
  };

  // Add the event listener when the component mounts
  document.addEventListener('click', handleClickOutside);

  // Clean up the event listener when the component unmounts
  return () => {
    document.removeEventListener('click', handleClickOutside);
  };
}, []);



useEffect(()=>{
const catalogDetails = async ()=>{
    const headers={
    authorization:localStorage.getItem("loginToken")
    }
    try{
        const getCatalogDetails = await axios.post(`${baseUrl}/api/v1/catalog/fetchProductsFromCatalogId`,{   
            "catalogId": id,
            "_id": localStorage.getItem("userId")
            },{
                headers,
            })
            const catalogProducts=[]
            for (let i=0; i<getCatalogDetails.data.length; i++){
                const productDetails = await axios.post(`${baseUrl}/api/v1/products/fetchProductDetails`,{
                "_id":getCatalogDetails.data[i].productId,
                } )
                catalogProducts.push(productDetails.data)
            }
            setCatalogProducts(catalogProducts)
            setCatalogDetails(getCatalogDetails.data)
            setShowShimmer(false)

    }
    catch(e){
        console.log(e)
    }
   
   }
   catalogDetails()
   
},[location.search,reload])


  const handleProductSelection = (productId) => {
    if(isBulkDeletedClicked){
        if (bulkDeleteList.includes(productId)) {
            const updatedList = bulkDeleteList.filter((id) => id !== productId);
            setBulkDeleteList(updatedList);
          } else {
            setBulkDeleteList([...bulkDeleteList, productId]);
          }
    }
    else{
        if (bulkUpdateList.includes(productId)) {
            const updatedList = bulkUpdateList.filter((id) => id !== productId);
            setBulkUpdateList(updatedList);
          } else {
            setBulkUpdateList([...bulkUpdateList, productId]);
          }

    }
  
  };


 const handleUpdateProduct = async () => {
    const margin = parseInt(updateMarginPrice)
   
    if(margin<=0 || isNaN(margin)){
      toast.warn("Margin Should Be Greater Than 0", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored", 
        });

    }
    else{
      try{
        const headers={
          authorization:localStorage.getItem("loginToken")
        }
      
        const filterProduct = catalogProducts.filter((each)=>{
          if(each._id === updateProductId){
            return each
          }
        })
        const cdi = catalogDetails.filter((each)=>{
            if(each.productId=== updateProductId){
                return each
            }
        })
        const productPrice= filterProduct[0].currentPrice

        const sellingPrice = updateMarginType === "percent" ? (productPrice*margin/100 + productPrice) : productPrice+ margin

        const addingProductToCatalog = await axios.put(`${baseUrl}/api/v1/catalog/updateProductsInCatalog`,{
          "productId":updateProductId,
          "catalogId":id,
          sellingPrice,
          "catalogDetailsId":cdi[0]._id,
      },{
        headers,
      })
  
      toast.success(`Product Updated Successfully`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        style: {
          background: 'rgb(5 150 105)', // Your custom color
          color: '#ffffff', // Text color
          fontWeight: 'bold', // Font weight
        },
        });
      setReload(!reload)
        
        
      }
      catch(e){
        console.log(e)
        toast.error("Error Occurred", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored", 
          });
      }
      setUpdateOpen(false); 

    }

  };

  
 const deleteProductFromCatalog = async()=>{

        const cdi = catalogDetails.filter((each)=>{
            if(each.productId=== updateProductId){
                return each
            }
        })

        try{
          const body={
            "catalogDetailsId": cdi[0]._id,
            "_id": localStorage.getItem("userId")
        }
        const headers ={
          authorization:localStorage.getItem("loginToken")
        }
        const deleteProductFromCatalog = await axios.delete(`${baseUrl}/api/v1/catalog/removeProductsFromCatalog`,{
          headers,
          data:body,
        })
    
        setReload(!reload)
        toast.success(`Product Deleted Successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
          });
         
    
        }
        catch(e){
          console.log(e)
          toast.error("Error Occurred", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored", 
            });
    
        }
      }

const toggleMenu = () => {
setIsMenuOpen(!isMenuOpen);
};

//delete catalog 
  const deleteCatalog = async()=>{

    try{
      const body={
        "catalogId": id,
        "_id": localStorage.getItem("userId")
    }
    const headers ={
      authorization:localStorage.getItem("loginToken")
    }
    const deleteCatalogResponse = await axios.delete(`${baseUrl}/api/v1/catalog/deleteCatalog`,{
      headers,
      data:body,
    })

    // setCatalogProducts([])
    setReload(!reload)
    toast.success(`Catalog Deleted Successfully`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      style: {
        background: 'rgb(5 150 105)', // Your custom color
        color: '#ffffff', // Text color
        fontWeight: 'bold', // Font weight
      },
      });
      navigate("/")
     

    }
    catch(e){
      console.log(e)
      toast.error("Error occurred While Deleting Catalog ", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored", 
        });

    }

  }


  const handleBulkPriceUpdate =  async () => {
    if(bulkMarginPrice.length===0){
      toast.warn(`Please Enter Margin Price`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
       
        });
    }
    else{
      const updatedProducts = catalogProducts.map((product) => {
        if (bulkUpdateList.includes(product._id)) {
          
          let updatedPrice = product.currentPrice; 
  
          if (bulkMarginType === 'percent') {
            updatedPrice = product.currentPrice + (product.currentPrice * bulkMarginPrice) / 100;
          } else if (bulkMarginType === 'rupee') {
            updatedPrice = (parseInt(product.currentPrice) + parseInt(bulkMarginPrice)).toString();
          }
  
          return {
            ...product,
            currentPrice: updatedPrice,
          };
        }
  
        // return product;
      }
      );
     
    
      for(let i = 0 ; i < updatedProducts.length; i++){
          try{
              const headers={
                authorization:localStorage.getItem("loginToken")
              }
              const cdi = catalogDetails.filter((each)=>{
                if(updatedProducts[i] !== undefined){
                  if(each.productId === updatedProducts[i]._id){
                    return each
                }
                }  
              })
        
          
              if(cdi.length>0){
                const addingProductToCatalog = await axios.put(`${baseUrl}/api/v1/catalog/updateProductsInCatalog`,{
                  "productId":updatedProducts[i]._id,
                  "catalogId":id,
                  "sellingPrice":updatedProducts[i].currentPrice,
                  "catalogDetailsId":cdi[0]._id,
              },{
                headers,
              })
              }
             
            }
            catch(e){
              console.log(e)
              toast.error("Error Occurred", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored", 
                });
            }
  
      }
      setBulkOpen(false)
      setCheckboxesVisible(false)
      toast.success(`Bulk Products Margin Updated Successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
          });
        setReload(!reload)
        setBulkUpdateList([]);

    }
   
    
  };


  const handleBulkDelete =  async () => {
      for(let i = 0 ; i < bulkDeleteList.length; i++){
          try{
              const headers={
                authorization:localStorage.getItem("loginToken")
              }
              const cdi = catalogDetails.filter((each)=>{
                  if(each.productId === bulkDeleteList[i]){
                      return each
                  }
              })
              const body={
                "catalogDetailsId": cdi[0]._id,
                "_id": localStorage.getItem("userId")
             }
         
             const deleteProductFromCatalog = await axios.delete(`${baseUrl}/api/v1/catalog/removeProductsFromCatalog`,{
              headers,
              data:body,
             })

            }
            catch(e){
              console.log(e)
              toast.error("Error Occurred", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored", 
                });
            }
  
      }
      setShowBulkDelete(false)
      setCheckboxesVisible(false)
      setIsBulkDeletedClicked(false)
      toast.success(`Bulk Products Deleted Successfully`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          style: {
            background: 'rgb(5 150 105)', // Your custom color
            color: '#ffffff', // Text color
            fontWeight: 'bold', // Font weight
          },
          });
        setReload(!reload)
        setBulkDeleteList([]);
};

if(showShimmer){
  return(
    <ShimmerPostDetails card cta variant="SIMPLE" />
  )
}
else{
  
  return (
    <div className='md:p-10 bg-[#F3F4F6] text-black '>
    <div className="container mx-auto p-4  ">
       
    <div className='flex justify-between w-full '>
        <h2 className="text-2xl font-bold mb-4">Catalog Products</h2>
    

            <div className='relative '>
                  <div className='cursor-pointer' onClick={toggleMenu}>
                   
                    <BiDotsVerticalRounded size={24} />
                  </div>
                 
                  {isMenuOpen && 
                  (
                       <div  className='absolute right-0 mt-2 bg-white border border-gray-200 py-2 px-4 shadow-lg min-w-[200px] rounded-xl'>
                        {
                          catalogProducts.length>0 && <Link to = {`/pdf/${id}`} className=' !no-underline text-black cursor-pointer hover:text-emerald-500 py-1'>
                          <div>
                       Export PDF
                       </div>
                      </Link>
                        }
                        
                       <div className='cursor-pointer hover:text-emerald-500 py-1' 
                       onClick={()=>{
                          setShowDeleteCatalog(true)
                       }}
                       >
                         Delete This Catalog
                       </div>
                       {
                        catalogProducts.length>0 &&
                        <>   
                        <div className='cursor-pointer hover:text-emerald-500 py-1' 
                        onClick={()=>{
                         setCheckboxesVisible(true)
                         setIsBulkDeletedClicked(false)
                         setBulkDeleteList([])
                      }} 
                        >
                          Change Margin In Bulk
                        </div>
                         <div className='cursor-pointer hover:text-emerald-500 py-1'
                         onClick={()=>{
                             setCheckboxesVisible(true)
                             setIsBulkDeletedClicked(true)
                             setBulkUpdateList([]);


                          }}  
                        >
                          Delete Items In Bulk
                        </div>
                        </> 
                       }
                     
                     </div>
                   )}
            </div>     
    </div>
        
   
    
      <div className={catalogProducts && catalogProducts.length>0? 'h-80 overflow-auto':""}>
      { catalogProducts && catalogProducts.length>0 ?
      
      catalogProducts.map((product,index) => (
        <div
          key={product.id}
          className="bg-white p-4 rounded-md shadow-md flex items-center mb-4 mt-4  "

        >
           {isBulkDeletedClicked ===  false && checkboxesVisible && <input type='checkBox' className='mr-5'  checked={bulkUpdateList.includes(product._id)}
           onChange={() => handleProductSelection(product._id)} /> }
           {isBulkDeletedClicked ===  true && checkboxesVisible && <input type='checkBox' className='mr-5'  checked={bulkDeleteList.includes(product._id)}
           onChange={() => handleProductSelection(product._id)} /> }
        <div className=' w-28 h-28  flex  justify-center p-3'>
       
            <img
              src={product.images[0]}
              alt={product.name}
              className="max-w-28 max-h-28 object-cover  rounded"
            />
        </div>


        <div className="md:flex md:justify-between w-full ml-3">
            <div>
              <h3 className="text-lg font-semibold  overflow-auto ">{product.name}</h3>
              <h3 className="text-lg font-semibold mb-3 md:mt-4"> ₹ {catalogDetails[index].sellingPrice.toLocaleString('en-IN')}</h3>
            </div>
          
          <div className="flex items-center space-x-6">
            <button
              className="text-emerald-500 hover:bg-emerald-500 hover:text-white transition duration-300 border border-emerald-500 p-2 rounded"
              title="Change Margin"
              onClick={()=>
                {setIsBulkDeletedClicked(false)
                  setCheckboxesVisible(false)
                  setUpdateOpen(true)
                setUpdateProductId(product._id)}}
            >
               Change Margin
            </button>
            <button
              className="text-red-500 hover:text-red-700 transition duration-300"
              title="Delete Product"
              onClick={()=>
                { setIsBulkDeletedClicked(false)
                  setCheckboxesVisible(false)
                  setShowDeleteProduct(true)
                setUpdateProductId(product._id)}}
            >
              <RiDeleteBin6Line size={24} />
            </button>
           
          </div>
        </div>
        </div>
        
      )):<NoProductsFound/>}
      </div>

      {
        isBulkDeletedClicked === false && checkboxesVisible &&  
        <div className='text-center mt-5'>
        <button className='bg-emerald-500 p-3 text-white rounded' onClick={()=>{
            if(bulkUpdateList.length===0){
                toast.warn("Please Select Items To Update", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored", 
                    });
                
            }
            else{
                setBulkOpen(true)
            }
           
        }}>
           Change Margin In Bulk
        </button>
        </div>
      }
      {
        isBulkDeletedClicked === true &&  checkboxesVisible && 
        <div className='text-center mt-5'>
        <button className='bg-emerald-500 p-3 text-white rounded ' onClick={()=>{
            if(bulkDeleteList.length===0){
                toast.warn("Please Select Items To Delete", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored", 
                    });
                
            }
            else{
                setShowBulkDelete(true)
            }
           
        }}>
           Delete Bulk Products
        </button>
        </div>
      }
     



        <Popup
        modal
        nested
        onClose={() => 
          {setUpdateOpen(false) 
            setUpdateMarginPrice(0)}}
        open={updateOpen} // Set the open state
        closeOnDocumentClick={false}
        contentStyle={{ width: "70%", maxWidth: "400px", padding: "20px" }} // Adjust width and padding as needed
      >
        {(close) => (
          <div className="">
            <h2 className="text-xl font-semibold mb-4">Update Margin</h2>

            {/* Margin Type Radio Buttons */}
            <div className="flex items-center mb-3">
              <label className="mr-3 cursor-pointer">
                <input
                  type="radio"
                  value="percent"
                  name="update"
                
                  checked={updateMarginType === "percent"}
                  onChange={() => setUpdateMarginType("percent")}
                />
                <span className="ml-1">Percentage</span>
              </label>
              <label className="cursor-pointer">
                <input
                  type="radio"
                  value="rupee"
                  name="update"
                  checked={updateMarginType === "rupee"}
                  onChange={() => setUpdateMarginType("rupee")}
                />
                <span className="ml-1">Rupee</span>
              </label>
            </div>

            {/* Margin Price Input */}
            <div className="flex items-center mb-3">
              <input
                type="number"
                placeholder="Enter margin price"
                className="flex-grow bg-gray-200 text-gray-800 p-2 rounded-l-md focus:outline-none border-2 no-scroll-input"
                value={updateMarginPrice}
                onWheel={handleWheel}
                onChange={(e) => {
                  setUpdateMarginPrice(e.target.value);
                }}
              />
              <span className="px-3">
                {updateMarginType === "percent" ? <FaPercent /> : <FaRupeeSign />}
              </span>
            </div>

            {/* Buttons */}
            <div className="flex justify-between">
              <button
                className="w-1/2 bg-gray-300 text-gray-800 py-2 rounded"
                onClick={close}
              >
                Cancel
              </button>
              <button
                className="w-1/2 bg-emerald-500 text-white py-2 rounded ml-4"
                onClick={handleUpdateProduct}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Popup>

       {/* bulk */}
      <Popup
        modal
        nested
        onClose={() => 
          {setBulkOpen(false) 
            setBulkMarginPrice(0)}}
        open={bulkOpen} // Set the open state
        closeOnDocumentClick={false}
        contentStyle={{ width: "70%", maxWidth: "400px", padding: "20px" }} // Adjust width and padding as needed
      >
        {(close) => (
          <div className="">
            <h2 className="text-xl font-semibold mb-4">Update Margin</h2>

            {/* Margin Type Radio Buttons */}
            <div className="flex items-center mb-3">
              <label className="mr-3 cursor-pointer">
                <input
                  type="radio"
                  value="percent"
                  name="update"
                
                  checked={bulkMarginType === "percent"}
                  onChange={() => setBulkMarginType("percent")}
                />
                <span className="ml-1">Percentage</span>
              </label>
              <label className="cursor-pointer">
                <input
                  type="radio"
                  value="rupee"
                  name="update"
                  checked={bulkMarginType === "rupee"}
                  onChange={() => setBulkMarginType("rupee")}
                />
                <span className="ml-1">Rupee</span>
              </label>
            </div>

            {/* Margin Price Input */}
            <div className="flex items-center mb-3">
              <input
                type="number"
                placeholder="Enter margin price"
                className="flex-grow bg-gray-200 text-gray-800 p-2 rounded-l-md focus:outline-none border-2 no-scroll-input"
                value={bulkMarginPrice}
                onWheel={handleWheel}
                onChange={(e) => {
                  setBulkMarginPrice(e.target.value);
                }}
              />
              <span className="px-3">
                {bulkMarginType === "percent" ? <FaPercent /> : <FaRupeeSign />}
              </span>
            </div>

            {/* Buttons */}
            <div className="flex justify-between">
              <button
                className="w-1/2 bg-gray-300 text-gray-800 py-2 rounded"
                onClick={close}
              >
                Cancel
              </button>
              <button
                className="w-1/2 bg-emerald-500 text-white py-2 rounded ml-4"
                onClick={handleBulkPriceUpdate}
              >
                Save
              </button>
            </div>
          </div>
        )}
      </Popup>

      <Popup
        open={showDeleteProduct}
        onClose={() => setShowDeleteProduct(false)}
        modal
        contentStyle={{
          maxWidth: '400px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {close => (
          <div>
            <p className="text-center font-bold ">Are You Sure You Want To Delete This Product? </p>
            <div className="mt-4 flex justify-between">
              <button
                className="flex-grow bg-emerald-500 text-white py-2 rounded-md mr-2 hover:bg-emerald-600"
                onClick={() => {
                  deleteProductFromCatalog()
                  close();
                }}
              >
                Yes
              </button>
              <button
                className="flex-grow bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400"
                onClick={() => close()}
              >
                No
              </button>
            </div>
          </div>
        )}
      </Popup>

      <Popup
        open={showBulkDelete}
        onClose={() => setShowBulkDelete(false)}
        modal
        contentStyle={{
          maxWidth: '400px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {close => (
          <div>
            <p className="text-center font-bold ">Are You Sure You Want To Bulk Delete Items? </p>
            <div className="mt-4 flex justify-between">
              <button
                className="flex-grow bg-emerald-500 text-white py-2 rounded-md mr-2 hover:bg-emerald-600"
                onClick={() => {
                 
                  handleBulkDelete()
                  close();
                }}
              >
                Yes
              </button>
              <button
                className="flex-grow bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400"
                onClick={() => close()}
              >
                No
              </button>
            </div>
          </div>
        )}
      </Popup>

      <Popup
        open={showDeleteCatalog}
        onClose={() => setShowDeleteCatalog(false)}
        modal
        contentStyle={{
          maxWidth: '400px',
          padding: '20px',
          borderRadius: '10px',
        }}
      >
        {close => (
          <div>
            <p className="text-center font-bold ">Are You Sure You Want To Delete This Catalog? </p>
            <div className="mt-4 flex justify-between">
              <button
                className="flex-grow bg-emerald-500 text-white py-2 rounded-md mr-2 hover:bg-emerald-600"
                onClick={() => {
                 deleteCatalog()
                  close();
                }}
              >
                Yes
              </button>
              <button
                className="flex-grow bg-gray-300 text-gray-800 py-2 rounded-md hover:bg-gray-400"
                onClick={() => close()}
              >
                No
              </button>
            </div>
          </div>
        )}
      </Popup>

    
    </div>
    </div>
  );

}

};

export default Catalog2;
