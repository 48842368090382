import moment from "moment";
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Logo from "../../assets/icon/logo-color.svg"
import NotFoundProduct from "../notFoundProduct/NotFoundProduct";
import OrderReceived from "../orderReceived/orderReceieved";
import { useNavigate } from "react-router-dom";
function Order({}) {
  let navigate = useNavigate()
  useEffect(() => {
    window.scrollTo(0, 0);
    const safeRoute =()=>{
      const loginToken = localStorage.getItem("loginToken")
      const userId = localStorage.getItem("userId")
      const secondStage = localStorage.getItem("secondStage")
      if(loginToken=== null || userId === null || secondStage === null){
        navigate("/")
      }
    }
    safeRoute()
  }, []);

  const { id } = useParams();
  const data = JSON.parse(localStorage.getItem(id));

  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  return (

    <div className=" bg-gray-50">
    <div className=" max-w-screen-2xl mx-auto py-5 px-3 flex justify-center sm:px-6">
      <div className="bg-white flex  border-emerald-500 border-2 shadow-md rounded-xl  w-auto">
        {/* Your content goes here */}
        <OrderReceived />
      </div>
    </div>
  </div>
  );
}

export default Order;
